import { Component, OnInit } from '@angular/core';
import { InscripcionesService } from '../services/inscripciones.service';
import { Global } from 'src/global';
// @ts-ignore
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-horario',
  templateUrl: './horario.component.html',
  styleUrls: ['./horario.component.css']
})
export class HorarioComponent implements OnInit {

  data: {
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
    regular: string;
  };

  planalumno: {
    carrera: {
      nombre: string
    }
  };

  horarioUrl;
  materias = [];
  banderesindatos: boolean = true;


  constructor(private inscripcionesService: InscripcionesService, private global: Global) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
    // this.verPermisos();
    this.consultarHorario();
  }

  horarioalumno(matricula){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/HorarioAlumnoPdf/0/${matricula}`);
  }

  generarpdf(){
    this.inscripcionesService.imprimirhorario(166, this.data.matricula);
  }

  consultarHorario(){
    this.inscripcionesService.consultaHorario(this.data.matricula).subscribe({
      next:(res: any) => {
        this.materias = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.materias.length == 0){
          this.banderesindatos = false;
        }
      }
    });
  }





}
