import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ServicioSocialService } from '../services/servicio-social.service';
import {IAngularMyDpOptions, IMyDateModel, CalAnimation} from 'angular-mydatepicker';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { Router } from '@angular/router';
declare var $:any;
declare var _:any;

@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: ['./formatos.component.css']
})
export class FormatosComponent implements OnInit {

  solicitudForm = new FormGroup({
    matricula: new FormControl('',[Validators.required] ),
    nombre_completo: new FormControl('', [Validators.required]),
    curp: new FormControl('', [Validators.required, Validators.minLength(18), Validators.maxLength(18)]),
    rfc: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(13)]),
    telefono:  new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    celular: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    domicilio: new FormControl('', [Validators.required]),
    sexo: new FormControl('', [Validators.required]),
    lugar_nacimiento: new FormControl('',[Validators.required]),
    estado_civil: new FormControl('',[Validators.required]),
    edad: new FormControl('',[Validators.required]),
    matriculap: new FormControl('',),
    semestre: new FormControl(false),
    extemporaneo: new FormControl(false),
    fechaegreso: new FormControl('',),
    ultimo_internado: new FormControl('',)
  });

  response = {
    codigo: '',
    detalle: '',
    mensaje: ''
  }

  constructor(private service:ServicioSocialService, private router: Router) { }
  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };
  src;
  planalumno:{
    carrera:{
      nombre: string
    }
  };
  banderaboton:boolean = true;
  banderaextemporaneo:boolean =true;
  respuesta;
  respuesta1;
  datos;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd-mm-yyyy',
    dayLabels:{
      su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sáb'
    },
    monthLabels:{
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Ago', 9: 'Sept', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    calendarAnimation:{
      in: CalAnimation.Fade,
      out: CalAnimation.Fade
    }
    // other options are here...
  };

  fecha: IMyDateModel = null;
  fechae= "";
  fecha_1 ;
  archivos;
  archivosalumno;
  bandera : boolean = true;
  doc;
  id_formato;
  tipo;
  mensajesmodal;

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
    this.consulta();
    this.consultarArchivos();
    this.consultarArchivosAlumno();
    //console.log(this.solicitudForm);
  }

  consulta(){
    let jason = {
      matricula: this.data.matricula
    }
    this.service.consultardatos(jason).subscribe({
      next:(data : any) =>{
        this.datos = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.datos);
        //this.estado();
        this.solicitudForm.get("nombre_completo").patchValue(this.datos.nombre_completo);
        this.solicitudForm.get("matricula").patchValue(this.datos.matricula);
        this.solicitudForm.get("curp").patchValue(this.datos.curp);
        this.solicitudForm.get("rfc").patchValue(this.datos.rfc);
        this.solicitudForm.get("telefono").patchValue((this.datos.telefono==undefined)?'':this.datos.telefono);
        this.solicitudForm.get("celular").patchValue(this.datos.celular);
        this.solicitudForm.get("email").patchValue(this.datos.email);
        this.solicitudForm.get("domicilio").patchValue(this.datos.domicilio);
        this.solicitudForm.get("sexo").patchValue((this.datos.sexo=='M')?'FEMENINO':((this.datos.sexo=='H')?'MASCULINO':''));
        this.solicitudForm.get("lugar_nacimiento").patchValue((this.datos.l_nacimiento==undefined)?'':this.datos.l_nacimiento);
        this.solicitudForm.get("estado_civil").patchValue(this.datos.e_civil);
        this.solicitudForm.get("edad").patchValue(this.datos.edad);
        this.solicitudForm.get("fechaegreso").patchValue(this.datos.fechaegreso);
        this.solicitudForm.get("ultimo_internado").patchValue(this.datos.ultimo);
        //console.log(this.solicitudForm.value);
        //console.log(this.datos.extemporaneo);
        
        this.solicitudForm.disable();

        this.bandera = false;
        if((this.datos.semestre>=this.datos.semestreple)&&(this.datos.extemporaneo==0) ){
          this.solicitudForm.get("semestre").patchValue(true);
          //console.log(this.solicitudForm.get('semestre').value);
          this.solicitudForm.get("semestre").enable();
          this.banderaextemporaneo = false;
          
        }else if(this.datos.extemporaneo== 1){
          //console.log(this.solicitudForm.get('extemporaneo').value);
          this.solicitudForm.get("extemporaneo").enable();
          this.solicitudForm.get("extemporaneo").patchValue(true);
          this.banderaextemporaneo = true;
        }
        
          this.editar();
        //this.solicitudForm.get("edad").patchValue(this.datos.edad);
      }
    });
  }
  documentos(){
    $('#documentos').modal('show');
  }
  consultarArchivos(){
    let jason={
      semestre:this.data.semestre
    }
    this.service.consultarArchivos(jason).subscribe({
      next:(data : any) =>{
        this.archivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.archivos);
      }
  });
  }
  consultarArchivosAlumno(){
    let jason={
      matricula:this.data.matricula,
      semestre:this.data.semestre
    }
    this.service.consultarArchivosAlumno(jason).subscribe({
      next:(data : any) =>{
        this.archivosalumno = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.archivos);
      }
  });
  }

  enviar(){
    let json={
      periodoId:0,
      extemporaneo:(this.banderaextemporaneo==true)?"SI":"NO",
      fechaEgreso:this.solicitudForm.get("fechaegreso").value,
      promocion:this.solicitudForm.get("ultimo_internado").value,
      lugarNacimiento: this.solicitudForm.get("lugar_nacimiento").value,
      matricula:this.data.matricula
    }
    //console.log(json);
    
    this.service.solicitud(json).subscribe({
      next:(data : any) =>{
        this.response = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#aceptar').modal('hide');
        
        this.service.descargarDocumento(this.data.matricula).subscribe({
          next:(data : ArrayBuffer) =>{
            this.respuesta1 = new Uint8Array(data);
          },
          error:(err) => {
            console.log(err);
          },
          complete:() => {
            
            if(this.response.codigo == "OK"){
              $('#nota').modal('show');
              var blob = new Blob([this.respuesta1], {type: "application/pdf;charset=utf-8"});
              saveAs(blob, `INTERNADO MÉDICO DE PREGRADO`);
              
            }else{
              $('#respuesta').modal('show');
            }
          }
      });
      }
    });
  }

  editar(){
        this.banderaboton = false;

        $('#avisoDatos').modal('show');
        this.solicitudForm.get("celular").enable();
        this.solicitudForm.get("email").enable();
        this.solicitudForm.get("lugar_nacimiento").enable();
        this.solicitudForm.get("estado_civil").enable();
        this.solicitudForm.get("fechaegreso").enable();
        this.solicitudForm.get("ultimo_internado").enable();
        //console.log(this.solicitudForm.invalid);
        
  }

  descargar(id, formato){
    this.service.descargarDocumento1(id).subscribe({
      next:(data : ArrayBuffer) =>{
        this.respuesta1 = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var blob = new Blob([this.respuesta1], {type: "application/pdf;charset=utf-8"});
        saveAs(blob, formato+' - INTERNADO MÉDICO DE PREGRADO');
      }
  });
  }

  guardardatos(){
    let json = {
      matricula:this.data.matricula,
      telefono: this.solicitudForm.get("telefono").value,
      celular: this.solicitudForm.get("celular").value,
      email:this.solicitudForm.get("email").value,
      l_nacimiento:this.solicitudForm.get("lugar_nacimiento").value,
      e_civil:this.solicitudForm.get("estado_civil").value,
      fechaegreso:this.solicitudForm.get("fechaegreso").value,
      ultimo:this.solicitudForm.get("ultimo_internado").value
    }
    this.service.actualizardatos(json).subscribe({
      next:(data : any) =>{
        this.response = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show')
        this.banderaboton = true;
        this.solicitudForm.get("celular").disable();
        this.solicitudForm.get("email").disable();
        this.solicitudForm.get("lugar_nacimiento").disable();
        this.solicitudForm.get("estado_civil").disable();
        this.solicitudForm.get("fechaegreso").disable();
        this.solicitudForm.get("ultimo_internado").disable();
        
      }
    });
  }

  handleFileSelect(evt,id){
    //console.log(evt);
    var files = evt.target.files;
    var file = files[0];
    if (file.size<1000000){
      if(file.type == "image/jpeg"){
        if (files && file) {
          var reader = new FileReader();
          reader.onload =this._handleReaderLoadedimg.bind(this);
          reader.readAsBinaryString(file);
          this.tipo= 'jpeg';
          this.id_formato = id;
          $('#documentos').modal('hide');
          $('#aceptacion').modal('show');
      }
      }else{
        if(file.type == "application/pdf"){
          if (files && file) {
              var reader = new FileReader();
              reader.onload =this._handleReaderLoadedpdf.bind(this);
              reader.readAsBinaryString(file);
          }
          this.id_formato = id; 
        this.tipo= 'pdf';
        $('#documentos').modal('hide');
        $('#aceptacion').modal('show');
        }
      }
    }else {
      this.respuesta={
        codigo:'',
        detalle:'El archivo debe ser menor a 1 MB',
        mensaje:'El archivo debe ser menor a 1 MB'
      };
      $('#respuesta').modal('show');
    }
    
    files = '';
  }

  _handleReaderLoadedimg(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.doc = btoa(binaryString);
  }

  _handleReaderLoadedpdf(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.doc = btoa(binaryString);
  }

  generar(){
    $('#aceptar').modal('show');
  }

  salir(){
    this.router.navigateByUrl('/inicio');
  }

  enviarDoc(){
    let jason ={
      formato:this.id_formato,
      archivo: this.doc,
      extencion:this.tipo,
      matricula: this.data.matricula
    } 
    //console.log(jason);
    this.service.subirArchivos(jason).subscribe({
      next:(data : any) =>{
        this.response = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#aceptacion').modal('hide');
        $('#respuesta').modal('show')
        
        this.id_formato = '';
        this.doc = '';
        this.tipo = '';
      }
    });
  }

  // estado(){
  //   if(this.datos.semestre<=this.datos.semestreple){
  //     this.mensajesmodal = "¡Necesitas ser por lo menos de "+this.datos.semestreple+"° semestre para acceder a esta esta sección!";
  //     $('#mensaje').modal('show');
  //   }
  // }
}
