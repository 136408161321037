import { Component, OnInit } from '@angular/core';
import { InscripcionesService } from '../services/inscripciones.service';
import { Router } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-inscripcion-primer-semestre',
  templateUrl: './inscripcion-primer-semestre.component.html',
  styleUrls: ['./inscripcion-primer-semestre.component.css']
})
export class InscripcionPrimerSemestreComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  constructor(private inscripcionesService: InscripcionesService, private router: Router) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.inscripcionPrimerSemestre();
  }

  inscripcionPrimerSemestre(){
    this.inscripcionesService.inscripcionPrimerSemestre(this.data.matricula).subscribe({
      next:(resp: any) => {
        this.respuesta = resp;
      },
      error:(err) => {
        console.log(err)
      },
      complete:() => {
        $('#inscripcionModal').modal('show');
      }
    });
  }

  resultado(){
    if(this.respuesta.codigo == "OK"){
      this.inscripcionesService.verPermiso(this.data.matricula).subscribe({
        next:(resp) => {
          localStorage.setItem('semaforo', JSON.stringify(resp));
        },
        error:(err) => {
          console.log(err);
        },
        complete:() => {
          this.router.navigateByUrl("/horario");
        }
      })
    }else{
      this.router.navigateByUrl('/inicio');
    }
  }

}
