import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubirDocumentosComponent } from './inscripciones/subir-documentos/subir-documentos.component';
import { InscripcionPrimerSemestreComponent } from './inscripciones/inscripcion-primer-semestre/inscripcion-primer-semestre.component';
import { HorarioComponent } from './inscripciones/horario/horario.component';
import { AuthGuardGuard } from './auth/guards/auth-guard.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { InicioComponent } from './servicio-social-universitario/inicio/inicio.component';
import { AgregarComponent } from './servicio-social-universitario/agregar/agregar.component';
import { ListadoComponent } from './servicio-social-universitario/listado/listado.component';
import { InicioExamenComponent } from './examen-profesionales/inicio/inicio.component';
import { DocumentosComponent } from './examen-profesionales/documentos/documentos.component';
import { InicioPerfilComponent } from './perfil/inicio/inicio.component';
import { DatosPersonalesComponent } from './perfil/datos-personales/datos-personales.component';
import { CambiarPasswordComponent } from './perfil/cambiar-password/cambiar-password.component';
import { DocumentosPerfilComponent } from './perfil/documentos/documentos.component';
import { KardexComponent } from './kardex/kardex/kardex.component';
import { InicioSSComponent } from './servicio-social/inicio/inicio.component';
import { FormatosComponent } from './servicio-social/formatos/formatos.component';
import {ListadoSolicitudesComponent} from './servicio-social/listado/listado.component';

const routes: Routes = [
  { 
    path:'inicio',
    loadChildren: () => import('./inicio/inicio.module').then(m => m.InicioModule),
    canLoad: [AuthGuardGuard]
  },
  {
    path:'inscripciones',
    loadChildren: () => import('./inscripciones/inscripciones.module').then(m => m.InscripcionesModule),
    canLoad: [AuthGuardGuard]
  },
  {
    path:'kardexs',
    loadChildren: () => import('./kardex/kardex.module').then(m => m.KardexModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'tramites',
    loadChildren: () => import('./tramites/tramites.module').then(m => m.TramitesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'servicio-social-universitario',
    loadChildren: () => import('./servicio-social-universitario/servicio-social-universitario.module').then(m => m.ServicioSocialUniversitarioModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'servicio-social',
    loadChildren: () => import('./servicio-social/servicio-social.module').then(m => m.ServicioSocialModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'examenes-profesionales',
    loadChildren: () => import('./examen-profesionales/examen-profesionales.module').then(m => m.ExamenProfesionalesModule),
    canLoad:[AuthGuardGuard]
  },
  {
    path:'mi-perfil',
    loadChildren: () => import('./perfil/perfil.module').then(m => m.PerfilModule),
    canLoad:[AuthGuardGuard]
  },
  { 
    path:'documentos', 
    component:SubirDocumentosComponent, 
    canActivate:[AuthGuard] 
  },
  { 
    path:'inscripcion', 
    component:InscripcionPrimerSemestreComponent, 
    canActivate:[AuthGuard] 
  },
  { 
    path:'horario', 
    component:HorarioComponent, 
    canActivate:[AuthGuard] 
  },

  {
    path:'servicio-universitario',
    component:InicioComponent,
    children:[
      {
        path:'agregar',
        component:AgregarComponent
      },
      {
        path:'listado',
        component:ListadoComponent
      }
    ]
  },
  {
    path:'examen-profesional',
    component:InicioExamenComponent,
    children:[
      {
        path:'documentos',
        component:DocumentosComponent
      }
    ]
  },
  {
    path:'perfil',
    component:InicioPerfilComponent,
    children:[
      {
        path:'datos-personales',
        component:DatosPersonalesComponent
      },
      {
        path:'cambiar-contraseña',
        component:CambiarPasswordComponent
      },
      {
        path:'documentos',
        component:DocumentosPerfilComponent
      }
    ]
  },
  {
    path:'kardex',
    component:KardexComponent
  }, 
  {
    path:'servicio',
    component:InicioSSComponent, 
    children:[
      {
        path:'solicitud',
        component:FormatosComponent
      },
      {
        path:'listado',
        component:ListadoSolicitudesComponent
      }
    ]
  },  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
