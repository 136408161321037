import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  planalumno:{
    carrera:{
      nombre: string
    }
  };

  settings = {
    columns:{
      documento:{
        title:'Documento',
        editable:false,
        width:'50%'
      },
      estatus:{
        title:'Estatus',
        editable:false,
        width:'50%',
        type: "html",
        valuePrepareFunction:(cell) => {
          if(cell === 'Rechazado'){
            return '<div class="cell_style">' + cell + '</div>'
          }else{
            return cell
          }
        }
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'        
  };

  documentos = [
    { id:1, documento:'Servicio Social', estatus:'Aceptado' },
    { id:2, documento:'Servicio social', estatus:'Aceptado' },
    { id:2, documento:'Biblioteca', estatus:'Rechazado' },
    { id:2, documento:'Resultao EGEL', estatus:'Aceptado' },
    { id:2, documento:'Kárdex', estatus:'Aceptado' },
    { id:2, documento:'Pagos', estatus:'Rechazado' },
    { id:2, documento:'Examen Practico', estatus:'Aceptado' }
  ]

  constructor() { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
  }

}
