<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-6">
                    <app-bienvenido icon="fas fa-circle" alumno="{{ data.nombres | uppercase }} {{ data.apellidop | uppercase }} {{ data.apellidom | uppercase }}" 
                    carrera="{{planalumno.carrera.nombre}}" matricula="{{ data.matricula }}"  semestre="{{ data.semestre }}"  regular="{{ data.regular }}"></app-bienvenido>
                    
                <div class="row" id="top"></div>
                  <div id="top" *ngIf="banderesindatos">
                      <div class="contentToConvert">
                        <ul class="list-group">
                            <li class="list-group-item" ><a type="button" class="btn btn-danger" (click)="horarioalumno(data.matricula)" target="blank">Imprimir</a></li>
                            <li class="list-group-item" *ngFor="let materia of materias" [hidden]="materia.egh?.length == 0">
                                <div>
                                    <div class="text-center">
                                        <label><span class="text-danger">{{ materia.grupo.mpe.materia.nombre }} {{ materia.grupo.alias }}</span> Semestre: <span class="text-danger">{{ materia.grupo.mpe.semestre }}</span> Grupo: <span class="text-danger">{{ materia.grupo.nombre }}</span></label><br>
                                        <label><span class="text-danger">Docente:</span> {{ materia.egh[0].maestro | titlecase }} <span class="text-danger">Correo:</span> {{ materia.egh[0].mail_maestro }}</label>
                                    </div>
                                    <div>
                                        <table class="table table-hover table-sm">
                                           <tbody>
                                               <tr>
                                                   <td *ngFor="let dia of materia.egh"><span class="text-danger">{{ dia.dia }}</span></td>
                                               </tr>
                                               <tr>
                                                   <td *ngFor="let hini of materia.egh"><span class="text-danger">I:</span> {{ hini.hinicio }}</td>
                                               </tr>
                                               <tr>
                                                   <td *ngFor="let hfin of materia.egh"><span class="text-danger">F:</span> {{ hfin.hfin }}</td>
                                               </tr>
                                               <tr>
                                                   <td *ngFor="let aula of materia.egh"><span class="text-danger">A:</span> {{ aula.aula.nombre }}</td> 
                                               </tr>
                                           </tbody> 
                                        </table>
                                    </div>
                                </div>
                            </li>
                        </ul>
                      </div>
                  </div>
                  <div class="row" *ngIf="!banderesindatos">
                    <div class="col-12">
                        <div class="text-center">
                            <h6>Sin datos de su horario disponible.</h6>
                        </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
</body>