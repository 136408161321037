import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  @Input() finished: any;

  alumno = JSON.parse(localStorage.getItem('alumno'))
  tiempoServidor: {
    fechaInscripcion: string,
    fechaServidor: string,
    matricula: string
  }

  fechaServidor: Date;
  fechaInscrip: Date;


  constructor(private sharedService: SharedService) { }

  ngOnInit(){}

}
