<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-10">
                <!-- <div class="row">
                    <div class="col-7">
                        <app-bienvenido icon="fas fa-circle" alumno="{{ data.nombres | uppercase }} {{ data.apellidop | uppercase }} {{ data.apellidom | uppercase }}" 
                        carrera="{{planalumno.carrera.nombre}}" matricula="{{ data.matricula }}"  semestre="{{ data.semestre }}"></app-bienvenido>
                        <div class="row" id="top"></div>
                    </div>
                </div> -->
                <div *ngIf="!bandera2" class="container-fluid data  bg-white">
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center">
                                <h2 class="lucida">Universidad Juárez del Estado de Durango</h2>
                                <h3 class="helvetica">FACULTAD DE MEDICINA Y NUTRICIÓN</h3>
                                <h4>LICENCIADO EN {{ datoscabecerakardex.carrera }}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="row" id="top"></div>
                    <div class="row">
                        <div class="col-sm-7 ml-2">
                            <div>
                                <h6><strong>Alumno:</strong> {{ datoscabecerakardex.alumno }}</h6>
                                <h6><strong>Calle:</strong> {{ datoscabecerakardex.calle }}</h6>
                                <h6><strong>Colonia:</strong> {{ datoscabecerakardex.colonia }}</h6>
                                <h6><strong>Procede:</strong> {{ datoscabecerakardex.procede }}</h6>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div>
                                <h6><strong>Matricula:</strong> {{ datoscabecerakardex.matricula }}</h6>
                                <h6><strong>Prom Materias aprob:</strong> {{ datoscabecerakardex.prommataprob }}</h6>
                                <h6><strong>Prom Completo:</strong> {{ datoscabecerakardex.prommatcompletas }}</h6>
                                <h6><strong>Créd Aprob:</strong> {{ datoscabecerakardex.credapro }}</h6>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 ml-2">
                            <h6><strong>Lugar de Nacimiento: Estado:</strong> {{ datoscabecerakardex.estado }}</h6>
                        </div>
                        <div class="col-sm-3">
                            <h6><strong>Municipio:</strong> {{ datoscabecerakardex.lugarnacimiento }}</h6>
                        </div>
                        <div class="col-sm-3">
                            <h6><strong>Fecha Nac:</strong> {{ datoscabecerakardex.fechanac }}</h6>
                        </div>
                    </div>
            
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="line">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table table-sm table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Cve:</th>
                                        <th scope="col">Cred</th>
                                        <th scope="col">Sem</th>
                                        <th scope="col">Materia</th>
                                        <th scope="col">Grupo:</th>
                                        <th scope="col">Acta:</th>
                                        <th scope="col">Calific.</th>
                                        <th scope="col">Fecha:</th>
                                        <th scope="col">Tipo Examen:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let materia of datoshistorialkardex; let i = index">
                                        <td>{{i + 1}}</td>
                                        <td>{{materia.cve}}</td>
                                        <td>{{materia.credito}}</td>
                                        <td>{{materia.semestre}}</td>
                                        <td>{{materia.materia}}</td>
                                        <td>{{materia.grupo}}</td>
                                        <td>{{materia.acta}}</td>
                                        <td>{{materia.calificacion}}</td>
                                        <td>{{materia.fecha}}</td>
                                        <td>{{materia.tipoexamen}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</body>