import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../../global';

interface Materia{
  id: number;
  clave: string;
  materia:{
    clave: string;
    creditos: string;
    horas: string;
    nombre: string;
    nombrec: string;
  },
  orden: number;
  renglon: number;
  semestre: number;
}

@Injectable({
  providedIn: 'root'
})
export class KardexService {

  constructor(private http: HttpClient, private global: Global) { }

  consultardatoskardex(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/kardex/${matricula}`, null);
  }

  consultarhistorialcabecera(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/kardex/historia/${matricula}`, null);
  }

}