import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class Global{
    protocolo = 'https';
    //  backendUrl = 'localhost:8080'; //local
    //  contexto = 'famen_escolares'; //local
    //backendUrl = '3.23.203.196:80'; //pruebas
    //contexto = 'famen_profesores'; //pruebas
    backendUrl = 'alumnos.famen.ujed.mx'; //prod
    contexto = 'famen_alumnos'; //prod
    headers = new HttpHeaders(
        {'Content-Type':'application/json; charset=utf-8'}
    );
}