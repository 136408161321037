import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
declare var $ : any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    usuario: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(15)]),
    password: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(15)])
  });


  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  verSemaforo(){
    this.authService.verPermiso(this.loginForm.controls["usuario"].value).subscribe({
      next:(resp) => {
        localStorage.setItem('semaforo', JSON.stringify(resp));
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });
  }

  datosalumno(data){
    this.authService.alumno(this.loginForm.controls["usuario"].value).subscribe({
      next: (resp) => {
        localStorage.setItem('alumno', JSON.stringify(resp));
        this.authService.alumnoPlan(this.loginForm.controls["usuario"].value).subscribe({
          next:(res) => {
            localStorage.setItem('planAlumno', JSON.stringify(res));
            this.loginForm.reset();
            if(data.detalle.charAt(0) != "1"){
              this.router.navigateByUrl('/changePass');
            }
            else{
              this.router.navigateByUrl('/inicio');
            }
          },
          error:() => {

          }
        })
      },
      error: () => {
        throw new Error('Error al encontrar alumnos');
      }
    });
  }

  login(){
    this.authService.login(this.loginForm.value).subscribe({
      next: (resp) => {
        let check = resp;
        if(resp.codigo === "OK"){
          this.datosalumno(check);
          this.verSemaforo();
        } else{
          this.loginForm.setErrors({ credentials: true });
          throw new Error('Acceso Denegado, Revise sus credenciales');
        }
      },
      error: (err) => {
        if(!err.status){
          this.loginForm.setErrors({ noConnection: true });
        } else{
          this.loginForm.setErrors({ unknownError: true });
        }
      }
    });
  }

  onSubmit(){
    if(this.loginForm.invalid){
      this.loginForm.setErrors({ incomplete: true });
    }else{
      this.login();
    }
  }


}
