import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Global } from '../../../global';

interface LoginCredentials{
  username: string;
  password: string;
}
interface LoginResponse{
  codigo: string;
  detalle: string;
  mensaje: string;
}

interface Alumnos{
  apellidoM: string;
  apellidoP: string;
  matricula: string;
  nombres: string;
  planEstudio: {
    clave: string;
    estatus: string;
    nombre: string;
  }
  semestre: string;
}

interface Plan{
  calificacionMinima: number,
  carrera: {
    claveOficial: number,
    estatus: string,
    id: number,
    nivelE: {
      descripcion: string,
      id: number,
      nombre: string
    },
    nombre: string
  },
  clave: string,
  estatus: string,
  id: number,
  nombre: string,
  semestres: number
}

interface nPassword{
  usuario: string,
  password: string
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  backendUrl = "http://192.168.1.153:8080";
  signedin$ = new BehaviorSubject(null);
  constructor(private http: HttpClient, private global: Global) { }

  login(credentials: LoginCredentials){
    return this.http.post<LoginResponse>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Login`, credentials).pipe(
      tap(() => {
        this.signedin$.next(true);
      })
    )
  }

  alumno(matricula: string){
    return this.http.post<Alumnos>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/matricula/${matricula}`, null)
  }

  alumnoPlan(matricula: string){
    // let headers = new HttpHeaders({'Authorization': "Bearer "+localStorage.getItem('token').replace(/"/g, '')});
    return this.http.post<Plan>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Plan/planAlumno/${matricula}`, null)
  }
  
  cambiarPass(nuevaP: nPassword){
    // let headers = new HttpHeaders({'Authorization': "Bearer "+localStorage.getItem('token').replace(/"/g, '')});
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Login/newpassword/user`, nuevaP)
  }

  resetPass(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Mailing/SendContrasenaAlumno/${matricula}`, null)
  }

  verPermiso(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/permiso/${matricula}`, null)
  }


}
