import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { InscripcionesService } from '../services/inscripciones.service';
import { Router } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-subir-documentos',
  templateUrl: './subir-documentos.component.html',
  styleUrls: ['./subir-documentos.component.css']
})
export class SubirDocumentosComponent implements OnInit {

  @ViewChild('inputFile') myInputVariable: ElementRef;

  documentos = [{
    id:Number,
    nombre:''
  }]

  documentosSubidos = [{
    archivo:'',
    id:Number,
    nombre:''
  }]

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  uploadForm: FormGroup;

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  respuesta2 = {
    codigo:'',
    detalle:'',
    mensaje:''
  }
  
  planalumno:{
    carrera:{
      nombre: string
    }
  };

  bandera = true;

  documentoSelectionado = {
    id:Number,
    nombre:''
  };

  semaforo = {
    documentos:'',
    horario:'',
    inscripcion:''
  }

  constructor(private formBuilder: FormBuilder, private router: Router ,private inscripcionesService: InscripcionesService) {

  }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
    this.inscripcionesService.documentosPosibles(this.data.matricula).subscribe({
      next:(res: any) => {
        this.documentos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentos.length <= 0){
          this.respuesta2.mensaje = "Todos los documentos subidos correctamente!"
          $('#exampleModalSalirCenter').modal('show');
        }
      }
    });
    this.inscripcionesService.listaDocumentosAlu(this.data.matricula).subscribe({
      next:(res: any) => {
        this.documentosSubidos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });

    this.uploadForm = this.formBuilder.group({
      file: ['', Validators.required, ]
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
  }


  onSubmit(){
    var sel = <HTMLInputElement>document.getElementById('scripts');
    var id;
    var file = this.uploadForm.get('file').value;
    for(var i = 0; i < this.documentos.length; i++){
      if(sel.value == this.documentos[i].nombre){
        id = this.documentos[i].id;
        break;
      }
    }
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('file').value);
    formData.append('matricula', this.data.matricula);
    formData.append('tipo', id);

    this.inscripcionesService.subirDocumento(formData).subscribe({
      next:(res: any) => {
        this.respuesta2 = res;
        if(this.respuesta2.codigo == "ERROR"){
          this.bandera = false;
        }else{
          $('#exampleModalCenter').modal('hide');
          setTimeout(() => {
            $('#exampleModalSubirRespCenter').modal('show');
          }, 500)
        }
      },
      error:(err) => {
        console.log(err);
      }
    });
    this.myInputVariable.nativeElement.value = '';
  }

  updateListado(){
    this.inscripcionesService.documentosPosibles(this.data.matricula).subscribe({
      next:(res: any) => {
        this.documentos = res;
      },
      error:(err) => {
        console.log(err);
      }
    });

    this.inscripcionesService.listaDocumentosAlu(this.data.matricula).subscribe({
      next:(res: any) => {
        this.documentosSubidos = res;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {

      }
    });

    this.inscripcionesService.verPermiso(this.data.matricula).subscribe({
      next:(res: any) => {
        localStorage.setItem('semaforo', JSON.stringify(res));
        this.semaforo = res;
        if(this.semaforo.inscripcion == "S"){
          this.respuesta2.mensaje = "En caso de dejar documentos pendientes por subir no obligatorios, contaras con 4 semanas para hacer la carga de los mismos."
        }
      },
      error:(err) => {

      },
      complete:() => {
        if(this.semaforo.inscripcion == "S"){
          this.respuesta2.mensaje = "En caso de dejar documentos pendientes por subir no obligatorios, contaras con 4 semanas para hacer la carga de los mismos."
          $('#exampleModalSalirCenter').modal('show');
        }
      }
    });
  }

  selectDocumento(documento: any){
    this.documentoSelectionado = documento;
  }

  routers(){
    this.router.navigateByUrl("/inscripcion")
  }

  borrarDocumento(){
    this.inscripcionesService.borrarDocumento(this.documentoSelectionado.id).subscribe({
      next:(res: any) => {
        this.respuesta = res;
      },
      complete:() => {
        $('#exampleModalBorrarRespCenter').modal('show');
        if(this.respuesta.codigo == "OK"){
          this.updateListado();
        }else{

        }
      }
      ,
      error:(err) => {
        console.log(err);
      }
    });

    this.inscripcionesService.verPermiso(this.data.matricula).subscribe({
      next:(res: any) => {
        localStorage.setItem('semaforo', JSON.stringify(res));
      },
      error:(err) => {

      }
    });

  }

  imprimirDocumento(id){
    this.inscripcionesService.descargarDocumento(id);
  }


}
