import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  settings = {
    columns:{
      semestre:{
        title:'Semestre',
        editable:false,
        width:'25%'
      },
      actividad:{
        title:'Actividad',
        editable:false,
        width:'25%'
      },
      avance:{
        title:'Horas',
        editable:false,
        filter:false,
        width:'25%',
      },
      total:{
        title:'Avance Horas',
        editable:false,
        filter:false,
        width:'25%',
        valuePrepareFunction: (cell, row) => {
          let total = 0;
          for( var i = 0; i < this.documentos.length; i++ ){
            total += this.documentos[i].avance;
            if(row.id == this.documentos[i].id){
              break;
            }
          }
          return total+"/"+row.total;
        }
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'        
  };

  documentos = [
    {id:1, semestre:5, actividad:'Limpieza', avance:50, total:400},
    {id:2, semestre:6, actividad:'Coronavirus', avance:40, total:400},
    {id:3, semestre:7, actividad:'Mecanica', avance:60, total:400}
  ];

  constructor() { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
  }

}
