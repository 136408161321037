<div class="div bg-white">
  <ul class="nav nav-pills flex-column bg-white">
    <div id="logo">
      <a class="navbar-brand">
        <img src="../../../assets/img/logov2.2.png" width="100%">
      </a>
    </div>
    <li class="nav-item" *ngFor="let link of links">
      <a class="nav-link" routerLinkActive="bg-danger text-white" [hidden]="link.hidden"  [class.disabled]="isDisabled(link.disabled)" [routerLink]="link.path"><span id="icon" class="{{ link.icon }}"></span>{{ link.title }}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="bg-danger text-white" (click)="verTutorial()"><span id="icon" class="fas fa-video"></span>Tutorial</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="bg-danger text-white" (click)="logout()"><span id="icon" class="fas fa-sign-out-alt"></span>Salir</a>
    </li>
  </ul>
</div>
<div class="version">
    <!-- <label>v5.0 09092020</label> -->
    <label>v{{currentApplicationVersion}}</label>
</div>

<div class="modal fade" id="aviso" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Atención</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Bienvenido a tu portal personal de la FAMEN. Si ya cuentas con tu expediente completo sigue adelante. 
        De lo contrario y para continuar con tu inscripción es necesario que actualices tus datos personales, 
        así como tu expediente digital subiendo cada uno de los archivos en FORMATO PDF que se te solicitan. 
        Después de esto puedes utilizar tu perfil de manera normal.
        <!-- Bienvenido a tu portal personal de la FAMEN. Si ya cuentas con tu expediente completo siga adelante. 
        De lo contrario y para continuar con tu inscripción es necesario que actualices tus datos personales, 
        así como tu expediente digital subiendo cada uno de los archivos en FORMATO PDF que se te solicitan. 
        Después de esto puedes utilizar tu perfil de manera normal. -->
        <!-- Bienvenido a tu portal personal de la FAMEN. Para continuar con tu inscripción es necesario que 
        actualices tus datos personales, así como actualizar tu expediente digital subiendo cada uno 
        de los archivos en <strong>FORMATO PDF</strong> que se te solicitan.
        Después de esto puedes utilizar tu perfil de manera normal.<br>
        <br>
        Si ya cuentas con tu expediente completo siga adelante y que de lo contrario complete la información -->
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal">Aceptar</button>
      </div>
    </div>
  </div>
</div>