import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Global } from '../../../global';
import { Observable, Subject } from 'rxjs';
import { BehaviorSubject } from "rxjs";

interface uploadDocumento{
  file: any,
  matricula: string,
  tipo: number
}


interface materiasPlanEstudio{
  cantidadrenglones: number,
  clave: string,
  cuentapromedio: string,
  id: number,
  materia: {
    acreditada: string,
    clave: string,
    creditos: string,
    horas: string,
    id: number,
    nombre: string,
    nombrec: string,
    practica: string,
  },
  orden: number,
  renglon: number,
  semestre: number,
  tipocalificacion: string,
  visible: number,
  repetida: number
}

interface Respuesta{
  codigo: string,
  detalle: string,
  mensaje: string
}

interface GruposHorario{
  aula:{
    capacidad: number,
    edificio: string,
    id: number,
    nombre: string
  },
  dia: string,
  grupo:{
    id: number,
    maestro: string,
    maximo: number,
    mpe:{
      cantidadrenglones: number,
      clave: string,
      cuentapromedio: number,
      id: number,
      materia:{
        acreditada: string,
        clave: string,
        creditos: string,
        horas: string,
        id: number,
        nombre: string,
        nombrec: string,
        practica: string
      },
      orden: number,
      renglon: number,
      semestre: number,
      tipocalificacion: string,
      visible: number
    },
    nombre: string,
    pescolares: any,
  },
  hfin: string,
  hinicio: string,
  id: number,
  maestro: string,
}

interface fechaServidor{
  fechainscripcion: string,
  fechaservidor: string,
  inscrito: string,
  inscritod: string,
  pagofamen: number,
  pagosesco: number,
  matricula: string
}





@Injectable({
  providedIn: 'root'
})
export class InscripcionesService {

  segundos:number;

  private subject = new Subject<any>();


  sendClickEvent() {
    this.subject.next();
  }
  sendClickEvent2() {
    this.subject.next();
  }
  getClickEvent(): Observable<any>{ 
    return this.subject.asObservable();
  }
  getClickEvent2(): Observable<any>{ 
    return this.subject.asObservable();
  }


  constructor(private http: HttpClient, private global: Global) { }


  materiasCursables(matricula: string){
    return this.http.post<materiasPlanEstudio>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/historiaInscribirtodo/${matricula}`, null)
  }

  gruposHorario(matricula: string){
    return this.http.post<GruposHorario>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/grupoHorariotodo/${matricula}`, null)
  }

  mandarMaterias(solicitud){
    return this.http.post<Respuesta>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/inserttodo`, solicitud)
  }

  fechaInscripcion(matricula: string){
    return this.http.post<fechaServidor>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Permiso/fecha/${matricula}`, null)
  }

  enviarCorreo(matricula: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Mailing/SendHorario/${matricula}`)
  }

  materiasElectivas(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/MPEstudio/historiaInscribirElectiva/${matricula}`, null)
  }


  mandarElectivas(materias: any){
    return this.http.post<Respuesta>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/insertElectiva`, materias)
  }

  listaMaterias(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Inscripciones/inscripcioneLista/${matricula}`, null)
  }
  gruposElectivos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/horarioAl/${matricula}`, null)
  }
   
  gruposHorarioElectivo(matricula: string){
    return this.http.post<GruposHorario>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/GruposH/grupoHorariotElectiva/${matricula}`, null)
  }

  gruposLlenos(matricula: string){
    return this.http.delete(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/delete/${matricula}`)
  }

  documentosPosibles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentos/${matricula}`, null)
  }

  listaDocumentosAlu(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentosAlumnos/${matricula}`, null)
  }

  borrarDocumento(idDocumento){
    return this.http.delete(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentosAlumnos/${idDocumento}`)
  }

  subirDocumento(uploadDocumento){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/documentos/upload`, uploadDocumento)
  }

  descargarDocumento(matricula: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${matricula}`, null)
  }

  verDocumento(matricula: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${matricula}`, { responseType: 'arraybuffer' })
  }

  inscripcionPrimerSemestre(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/inscrito/${matricula}`, null)
  }

  verPermiso(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/permiso/${matricula}`, null)
  }

  consultaHorario(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Inscripciones/inscripcionelistamateriasalumnos/${matricula}`, null)
  }

  imprimirhorario(periodoid, matricula){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/HorarioAlumnoPdf/${periodoid}/${matricula}`)
  }

  consultarPeriodo(parametros){
     return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/PeriodosEs/periodos`, parametros)
  }

}
