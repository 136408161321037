<div class="container">
    <div class="row">
        <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-6 col-md-8 mx-auto">
                    <div class="card rounded shadow shadow-sm">
                        <div class="card-header" id="header">
                            <!-- <h3 class="mb-0">Inicia Sesion</h3> -->
                            <div>
                                <img src="../../../assets/img/logov2.2.png" width="100%">
                            </div>
                            <div class="text-center">
                                <label>Ingresa tu matricula y te enviaremos un enlace por <strong>correo electrónico</strong> registrado con instrucciones para que puedas restablecer tu contraseña</label>
                            </div>
                        </div>
                        <div class="card-body">
                            <div [hidden]="!bandera">
                                <form (ngSubmit)="onSubmit()" [formGroup]="resetForm">
                                    <app-input icon="fas fa-user" placeholder="Matrícula" type="text" [control]="this.resetForm.get('matricula')"></app-input>
                                    <div class="button">
                                        <button class="btn btn-danger mr-3" type="submit" [disabled]="this.resetForm.invalid">Restablecer contraseña</button>
                                        <button class="btn btn-danger" routerLink="/login">Regresar</button>
                                    </div>
                                </form>
                            </div>
                            <div [hidden]="bandera">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border text-danger" role="status">
                                      <span class="sr-only">Loading...</span>
                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade" id="myModal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            {{ response.mensaje }}
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="redirect()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>