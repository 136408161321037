import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl  } from '@angular/forms';
import { PerfilService } from '../services/perfil.service';
declare var $ : any;

@Component({
  selector: 'app-cambiar-password',
  templateUrl: './cambiar-password.component.html',
  styleUrls: ['./cambiar-password.component.css']
})
export class CambiarPasswordComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  planalumno:{
    carrera:{
      nombre: string
    }
  };

  contraform = this.formbuilder.group({
    contraseñaanterior: ['', [Validators.required, Validators.minLength(8)]],
    contraseñanueva: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)]],
    contraseñaconfirmar: ['', [Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)]]
  },
  {
    validators:[
      this.contraseñasiguales('contraseñanueva','contraseñaconfirmar'),
      this.contraseñasanterioriguales('contraseñanueva', 'contraseñaanterior')
    ]
  });

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  constructor(public formbuilder: FormBuilder, public perfilService: PerfilService) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
  }

  contraseñasiguales(contraseñanueva, contraseñaconfirmar){
    return ( formGroup: FormGroup ) => {
      const contraseñanuevacontrol = formGroup.get(contraseñanueva);
      const contraseñaconfirmarcontrol = formGroup.get(contraseñaconfirmar);
      if(contraseñanuevacontrol.value === contraseñaconfirmarcontrol.value){

      }else{
        contraseñaconfirmarcontrol.setErrors({ noesigual:true })
      }
    }
  }

  contraseñasanterioriguales(contraseñanueva, contraseñaanterior){
    return ( formGroup: FormGroup ) => {
      const contraseñanuevacontrol = formGroup.get(contraseñanueva);
      const contraseñacanteriorcontrol = formGroup.get(contraseñaanterior);
      if(contraseñanuevacontrol.value === ''){

      }else{
        if(contraseñanuevacontrol.value === contraseñacanteriorcontrol.value){
          contraseñanuevacontrol.setErrors({ esigual:true })
        }else{

        }
      }
    }
  }

  cambiarcontra(){
    var datos = {
      usuario:this.data.matricula,
      password:this.contraform.get('contraseñanueva').value,
      oldpassword: this.contraform.get('contraseñaanterior').value
    }
    this.perfilService.cambiarPass(datos).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#respuesta').modal('show');
        this.contraform.reset();
      }
    });
  }

}
