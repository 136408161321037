import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class ServicioSUService {

  constructor(private http: HttpClient, private global: Global) { }

  registrodesolicitud(formdata){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssu/altaActividad`,formdata)
  }

}
