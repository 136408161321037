import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { Console } from 'console';
import { ServicioSUService } from '../service/servicio-su.service';
declare var $ : any;

@Component({
  selector: 'app-agregar',
  templateUrl: './agregar.component.html',
  styleUrls: ['./agregar.component.css']
})
export class AgregarComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  }

  banderatabla:boolean = true;
  
  uploadForm = new FormGroup({
    id: new FormControl(0),
    actividad: new FormControl('', [Validators.required]),
    horas: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
    file:new FormControl('', [Validators.required])
  });

  constructor(private servicioSU: ServicioSUService) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
    }
    if(this.uploadForm.get('file').value != ''){
      this.banderatabla = false;
    }
  }

  guardar(){
    // $('#load').modal('show');
    const formdata = new FormData();
    formdata.append('matricula',this.data.matricula);
    formdata.append('actividad', this.uploadForm.get('actividad').value);
    formdata.append('horas', this.uploadForm.get('horas').value);
    formdata.append('file', this.uploadForm.get('file').value);
    // console.log(this.uploadForm.get('file').value);
    this.servicioSU.registrodesolicitud(formdata).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#load').modal('hide');
        if(this.respuesta.codigo == "OK"){
          this.uploadForm.reset();
        }
        setTimeout(() => {
          $('#respuesta').modal('show');
        }, 1000);
      }
    });
  }

  cerrarmodalrespuseta(){
    $('#respuesta').modal('hide');
  }

}
