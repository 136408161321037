<div>
    <h4>FORMATOS DE SOLICITUD</h4>
    
    <form >
    <div class="row" *ngIf="!bandera">
        <div class="col-2">
            <small>Matricula</small>
            <input type="text" class="form-control" [formControl]="this.solicitudForm.get('matricula')"placeholder="Matricula">
        </div>
        <div class="col-4">
            <small>Nombre completo</small>
            <p class="m-2">{{this.datos.nombre_completo}}</p>
        </div>
        <div class="col-6">
        </div>
        <div class="col-6">
            <small>CURP</small>
            <input type="text" class="form-control mt-2" placeholder="CURP" [formControl]="this.solicitudForm.get('curp')">
        </div>
        <div class="col-6">
            <small>RFC</small>
            <input type="text" class="form-control mt-2" placeholder="RFC" [formControl]="this.solicitudForm.get('rfc')">
        </div>
        <div class="col-6">
            <small>Teléfono de casa</small>
            <input type="text" class="form-control mt-2" placeholder="Teléfono casa"[formControl]="this.solicitudForm.get('telefono')">
        </div>
        <div class="col-6">
            <small>Teléfono celular</small>
            <input type="text" class="form-control mt-2" placeholder="Teléfono celular" [formControl]="this.solicitudForm.get('celular')" id="celular">
            <div *ngIf="solicitudForm.invalid && (solicitudForm.controls.celular.touched || solicitudForm.controls.celular.dirty )">
                <div class="text-danger" *ngIf="solicitudForm.controls.celular.errors?.required">Ingrese su telefono.</div>
                <div class="text-danger" *ngIf="solicitudForm.controls.celular.errors?.maxlength">Numero de telefono no debe exceder los 10 digitos.</div>
                <div class="text-danger" *ngIf="solicitudForm.controls.celular.errors?.minlength">Numero de telefono no debe ser menos de 10 digitos.</div>
                <div class="text-danger" *ngIf="solicitudForm.controls.celular.errors?.pattern">Numero de telefono solo debe contener caracteres numericos.</div>
            </div>
        </div>
        <div class="col-6">
            <small>Correo electrónico</small>
            <input type="text" class="form-control mt-2" placeholder="Correo eléctronico" [formControl]="this.solicitudForm.get('email')" id="email">
            <div *ngIf="solicitudForm.invalid && (solicitudForm.controls.email.touched || solicitudForm.controls.email.dirty )">
                <div class="text-danger" *ngIf="solicitudForm.controls.email.errors?.required">Ingrese su correo personal.</div>
                <div class="text-danger" *ngIf="solicitudForm.controls.email.errors?.pattern">Correo ingresado no es valido.</div>
            </div>
        </div>
        <div class="col-6">
            <small>Domicilio</small>
            <input type="text" class="form-control mt-2" placeholder="Domicilio" [formControl]="this.solicitudForm.get('domicilio')">
        </div>
        <div class="col-6">
            <small>Sexo</small>
            <input type="text" class="form-control mt-2" placeholder="Sexo" [formControl]="this.solicitudForm.get('sexo')">
        </div>
        <div class="col-6">
            <small>Lugar de nacimiento</small>
            <input type="text" class="form-control mt-2" placeholder="Lugar de Nacimiento" [formControl]="this.solicitudForm.get('lugar_nacimiento')" id="lugar_nacimiento">
            <div *ngIf="solicitudForm.invalid && (solicitudForm.controls.lugar_nacimiento.touched || solicitudForm.controls.lugar_nacimiento.dirty )">
                <div class="text-danger" *ngIf="solicitudForm.controls.lugar_nacimiento.errors?.required">Ingrese su lugar de nacimiento.</div>
            </div>
        </div>
        <div class="col-6">
            <small>Estado civil</small>
            <input type="text" class="form-control mt-2" placeholder="Estado Civil" [formControl]="this.solicitudForm.get('estado_civil')">
        </div>
        <div class="col-6">
            <small>Edad</small>
            <input type="text" class="form-control mt-2" placeholder="Edad" [formControl]="this.solicitudForm.get('edad')">
        </div>
        
        <div class="col-3 ml-4 mt-2 form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" [checked]="this.solicitudForm.get('semestre').value" value="option1" [formControl]="this.solicitudForm.get('semestre')">
            <label class="form-check-label" for="inlineRadio1">{{datos.semestre}}° Semestre</label>
        </div>
        <div class="col-3  mt-2 form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" [checked]="this.solicitudForm.get('extemporaneo').value" [formControl]="this.solicitudForm.get('extemporaneo')">
            <label class="form-check-label" for="inlineRadio2">Soy Extemporáneo</label>
        </div>
        <div *ngIf="banderaextemporaneo" class="col-6 mt-2">
            Fecha de engreso de la licenciatura de Medicina: 
        </div>
        <div *ngIf="banderaextemporaneo" class="col-6 mt-2">
            <!-- <div class="input-group-append">
                    <input class="form-control" name="mydate" placeholder="Fecha Egreso" 
                    angular-mydatepicker #dp="angular-mydatepicker" 
                    [(ngModel)]="fecha" [options]="myDpOptions" 
                    (dateChanged)="fechaegre($event)"/>
                <button type="button" class="btn btn-danger" (click)="dp.toggleCalendar()">
                    <i class="fas fa-calendar-alt"></i>
                </button>
            </div> -->
            <input type="date" class="form-control " placeholder="Fecha Egreso" [formControl]="this.solicitudForm.get('fechaegreso')">
        </div>
        <div *ngIf="banderaextemporaneo"class="col-6 mt-2">
            Promoción en la que estuvo la última vez en que el internado Médico:
        </div>
        <div *ngIf="banderaextemporaneo" class="col-6 mt-2">
            <input type="text" class="form-control " placeholder="Último internado" [formControl]="this.solicitudForm.get('ultimo_internado')">
        </div>
    </div>
    
    <button *ngIf="banderaboton" class="btn btn-danger mt-2" [disabled]="solicitudForm.invalid" (click)="generar()">Generar formato</button> 
    
    <a *ngIf="banderaboton"class="btn btn-danger mt-2 ml-2 text-white" (click)="editar()">Editar</a>
    <!-- <a *ngIf="banderaboton "class="btn btn-danger mt-2 ml-2 text-white" (click)="documentos()">Documentos</a> -->
    <button *ngIf="!banderaboton" class="btn btn-danger mt-2 ml-2 text-white" [disabled]="solicitudForm.invalid" (click)="guardardatos()">Guardar datos</button>

    </form> 
    <small class="text-danger" *ngIf="solicitudForm.invalid">Llena todos los datos requeridos</small>
    <div class="modal" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            {{ response.mensaje }}
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
            </div>
        </div>
        </div>
    </div>

    <div class="modal" id="aceptar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <p>¿Estas seguro de generar el formato?, está acción también generara el <b>una nueva solicitud del internado</b>
            </p>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" [disabled]="solicitudForm.invalid" data-dismiss="modal">Cancelar</button>
            <a class="btn btn-danger text-white" (click)="enviar()">Enviar</a>
            </div>
        </div>
        </div>
    </div>
    
    <div class="modal" id="avisoDatos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <h5>Verifica tus datos personales antes de hacer una solicitud, 
                    cuando un espacio vacío esta desactivado, da click en el menú <b>Datos Personales</b> 
                    para realizar la actualización</h5>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary"  data-dismiss="modal">Cerrar</button>
            </div>
        </div>
        </div>
    </div>

    <div class="modal" id="aceptacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            ¿Estas seguro de subir el archivo?
            <p></p>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <a class="btn btn-danger text-white" (click)="enviarDoc()">Enviar</a>
            </div>
        </div>
        </div>
    </div>

    <div class="modal" id="documentos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Formatos
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <h5>Descargar formato</h5>
                        <table class="table table-light table-striped table-hover table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th style="width: 75%">Título</th>
                                    <th>Descarga</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of archivos">
                                    <td>{{a.formato}}</td>
                                    <td><a class="btn" (click)="descargar( a.id, a.formato )"><i class="fas fa-download text-danger"></i></a></td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                    <div class="col-6">
                        <h5>Subir documentos</h5>
                        <table class="table table-light table-striped table-hover table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th style="width: 65%">Título</th>
                                    <th>Subir documento</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of archivosalumno">
                                    <td>{{a.formato}}</td>
                                    <td>
                                        <label for="documento" class="btn btn-sm text-danger"><i class="fas fa-upload"></i></label>
                                        <input type="file" id="documento" (change)="handleFileSelect($event, a.id)" enctype="multipart/form-data" accept="image/jpeg, application/pdf" style="display: none">  
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
            </div>
        </div>
        </div>
    </div>

    <div class="modal" id="nota" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            <p>
                Recuerdad que tienes que <b>subir la documentación necesaria</b>en la opción de <b>Consultar solicitudes</b> para que se valide tu tramite. 
            </p>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Aceptar</button>
            </div>
        </div>
        </div>
    </div>
    

</div>