<div class="container">
    <div class="row">
        <div class="col-md-12 min-vh-100 d-flex flex-column justify-content-center">
            <div class="row">
                <div class="col-lg-6 col-md-8 mx-auto">
                    <div class="card rounded shadow shadow-sm">
                        <div class="card-header" id="header">
                            <h6 class="mb-0">
                                <div id="strong"><strong >¡Cambia tu contraseña!</strong></div>
                                <ul>
                                    <li>Mínimo 8 caracteres</li>
                                    <li>Mínimo una letra mayúscula</li>
                                    <li>Mínimo una letra minúscula</li>
                                    <li>Mínimo un número</li>
                                </ul>
                            </h6> 
                        </div>
                        <div class="card-body">
                            <form (ngSubmit)="onSubmit()" [formGroup]="changePasswordForm">
                                <app-input icon="fas fa-lock" placeholder="Antigua contraseña" type="password" [control]="this.changePasswordForm.get('passwordA')"></app-input>
                                <app-input icon="fas fa-lock" placeholder="Contraseña" type="password" [control]="this.changePasswordForm.get('password')"></app-input>
                                <app-input icon="fas fa-lock" placeholder="Confirmar Contraseña" type="password" [control]="this.changePasswordForm.get('passwordConfirmation')"></app-input>
                                <div *ngIf="changePasswordForm.errors"  class="alert alert-danger" role="alert">
                                    <p *ngIf="changePasswordForm.errors.noMatch">
                                        ¡La contraseña nueva y la confirmacion no coinciden!
                                    </p>
                                    <p *ngIf="changePasswordForm.errors.iguales">
                                        ¡No puedes utilizar tu contraseña anterior! 
                                    </p>
                                </div>
                                <div class="bt">
                                    <button type="submit" class="btn btn-danger" [disabled]="this.changePasswordForm.invalid">Ingresar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="myModal" tabindex="-1" data-backdrop="static" data-keyboard="false" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                {{ response.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal" *ngIf="!banedra" (click)="redirect()">Aceptar</button>
              <button type="button" class="btn btn-danger" data-dismiss="modal" *ngIf="banedra" >Salir</button>
            </div>
          </div>
        </div>
      </div>
</div>