import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $ : any;
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioSSComponent implements OnInit {

  data;

  planalumno:{
    carrera:{
      nombre: string
    }
  };
  mensajesmodal
  constructor(private router: Router ) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
    this.estado();    
  }
  estado(){
    let semestre = parseInt(this.data.semestre);
    let plesem =parseInt(this.data.plesemestres);
    //console.log(semestre);
    
    if((semestre<plesem-1) ){
      this.mensajesmodal = "¡Necesitas ser por lo menos de "+plesem+"° semestre para acceder a esta esta sección!";
      $('#mensaje').modal('show');
    }
    if((this.data.estatus == "BAJA DEFINITIVA") || (this.data.estatus == "BAJA TEMPORAL") || (this.data.estatus == "BAJA AUTOMATICA") || (this.data.estatus == "NO INSCRITO") ){
      this.mensajesmodal = "¡No Puedes acceder a esta seccion ya que cuentas con una baja o no estas inscrito!";
      $('#mensaje').modal('show');
    }
  }
  
  salir(){
    this.router.navigateByUrl('/inicio');
  }

}
