import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from '../../global';

interface FechaServidor{
  fechaInscripcion: string,
  fechaServidor: string,
  matricula: string
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient, private global: Global) { }

  fechaInscripcion(matricula: string){
    return this.http.post<FechaServidor>(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Permiso/fecha/${matricula}`, null)
  }

  verHorario(matricula: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Mailing/SendHorario/consulta/${matricula}`)
  }

  verPermiso(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/permiso/${matricula}`, null)
  }

}
