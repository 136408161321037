import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgDragDropModule } from 'ng-drag-drop';
import { CountdownModule } from 'ngx-countdown';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { Global } from 'src/global';
import { SafePipeModule } from 'safe-pipe';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AngularFileViewerModule } from '@taldor-ltd/angular-file-viewer';
import { PermisosComponent } from './personal/permisos/permisos.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
  declarations: [
    AppComponent,
    PermisosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    NgDragDropModule.forRoot(),
    CountdownModule,
    SafePipeModule,
    Ng2SmartTableModule,
    AngularFileViewerModule,
    AngularMyDatePickerModule
  ],
  providers: [ Global, {provide: LocationStrategy, useClass: HashLocationStrategy} ],
  bootstrap: [AppComponent]
})
export class AppModule { }
