<body>
    <div class="row">
        <div class="col-6">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <input type="text" class="form-control" [formControl]="this.uploadForm.get('actividad')"  id="matricula" placeholder="Actividad">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <div class="form-group">
                        <input type="numer" class="form-control" [formControl]="this.uploadForm.get('horas')" id="horas" placeholder="Horas">
                    </div>
                </div>
                <div class="col-6">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="custom-file">
                                <label for="documento" class="btn btn-link text-danger"><i class="fas fa-upload"></i>Subir documento</label>
                                <input type="file" (change)="onFileSelect($event)" id="documento" enctype="multipart/form-data" accept="application/pdf" style="display: none">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6" *ngIf="!banderatabla">
            <table class="table table-sm table-hover table-borderless">
                <thead class="bg-danger text-white">
                    <tr>
                        <th>Documento</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ uploadForm.get('file').value.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-12 ">
            <button class="btn btn-danger" (click)="guardar()" [disabled]="uploadForm.invalid">Guardar</button>
        </div>
    </div>

    <div class="modal fade" id="load" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
                <h6>Subiendo Documento</h6>
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                      <span class="sr-only text-danger">Loading...</span>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarmodalrespuseta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarmodalrespuseta()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

</body>