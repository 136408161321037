import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { PerfilService } from '../services/perfil.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileMimeType } from '@taldor-ltd/angular-file-viewer';
import { Global } from 'src/global';
import { Router } from '@angular/router';

declare var $ : any;


@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosPerfilComponent implements OnInit {

  @ViewChild('inputFile') myInputVariable: ElementRef;


  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  planalumno:{
    carrera:{
      nombre: string
    }
  };

  settings = {
    columns:{
      nombre:{
        title:'Documento',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell.substring(0,3) == "(*)"){
            return cell.slice(4)
          }else{
            return cell
          }
        },
        width:'50%'
      },
      obligatorio:{
        title:'Obligatorio',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell == "N"){
            cell = "NO";
            return cell;
          }else{
            if(cell == "S"){
              cell = "SI";
              return cell;
            }else{
              return cell
            }
          }
        },
        width:'50%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'abrirmodaldocumento', title: '<i class="fas fa-upload"></i>'}
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'
  }

  settings2 = {
    columns:{
      nombre:{
        title:'Documento',
        editable:false,
        filter:false,
        valuePrepareFunction:(cell, row) => {
          if(cell.substring(0,3) == "(*)"){
            return cell.slice(4)
          }else{
            return cell
          }
        },
        width:'100%'
      }
    },
    actions:{
      columnTitle:'',
      add:false,
      edit:false,
      delete:false,
      custom:[
        { name: 'verdocumento', title: '<i class="fas fa-eye"></i>'},
        { name: 'abrirmodaldocumento', title: '<i class="fas fa-upload"></i>'}      
      ],
      position:'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin documentos disponibles.'
  }

  documentosposibles = [];
  documentossubidos = [];
  documentosvalidados = [];
  documentoselecionadoid = '';
  documentoselecionadonombre = '';
  banderadocumentossubidos:boolean = true;
  banderadocumentospendientes:boolean = true;
  banderadocumentosvalidados:boolean = true;
  banderaterminado:boolean = true;
  banderatabla:boolean = true;
  banderadocumento:boolean = true;

  uploadForm = new FormGroup({
    file: new FormControl('', [Validators.required])
  });

  semaforo = JSON.parse(localStorage.getItem('semaforo'));

  respuesta = {
    codigo:'',
    detalle:'',
    mensaje:''
  };
  src;
  type = FileMimeType.PDF;
  datosgenerales;
  banderaextension:boolean = true;

  constructor(private global: Global, private router: Router ,private perfilService: PerfilService) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
    this.consultardocumentosposibles();
    this.consultardatosgenerales();
  }

  accion(valores){
    switch (valores.action)
    {
      case "verdocumento":
        this.verdocumento(valores)
          break;
      case "abrirmodaldocumento": 
        this.abrirmodaldocumento2(valores)
          break;
      default: 
        break;
    }
  }

  reset(){
    var doc = <HTMLSelectElement>document.getElementById('documento');
    this.banderatabla = true;
    this.banderaextension = true;
    this.uploadForm.reset({file:''});
    doc.value = "";
  }

  consultardatosgenerales(){
    this.perfilService.consultardatosgeneralesalumno(this.data.matricula).subscribe({
      next:(data : any) => {
        this.datosgenerales = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.data.semestre <= "1" && this.semaforo.documentos == "S" && this.semaforo.inscripcion == "N"){
          if(
            (this.datosgenerales.nombres == "" || null || undefined) || (this.datosgenerales.apellidop == "" || null || undefined) || (this.datosgenerales.apellidom == "" || null || undefined) ||
            (this.datosgenerales.matricula == "" || null || undefined) || (this.datosgenerales.fechanacimiento == "" || null || undefined) || (this.datosgenerales.curp == "" || null || undefined) ||
            (this.datosgenerales.calle == "" || null || undefined) || (this.datosgenerales.colonia == "" || null || undefined) || (this.datosgenerales.codigopostal == "" || null || undefined) ||
            (this.datosgenerales.email == "" || null || undefined) || (this.datosgenerales.telefono == "" || null || undefined)
          ){
            this.router.navigateByUrl('/perfil/datos-personales');
          }
        }
      }
    });
  }

  semaforoactualizar(){
    this.perfilService.verPermiso(this.data.matricula).subscribe({
      next:(resp: any) => {
        localStorage.setItem('semaforo', JSON.stringify(resp));
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semaforo = JSON.parse(localStorage.getItem('semaforo'));
        if(this.data.semestre <= "1" && this.semaforo.documentos == "S"){
          this.router.navigateByUrl('/perfil/documentos');
        }
        if(this.data.semestre <= "1" &&  this.semaforo.inscripcion == "S" && this.semaforo.documentos == "N"){
          this.router.navigateByUrl('/inscripcion');
        }
        // if(this.data.semestre == "1" && this.semaforo.horario == "S"){
        //   this.router.navigateByUrl('/horario');
        // }
      }
    });
  }

  onFileSelect(event) {
    this.banderaextension = true;
    this.banderatabla = true;
    var name = event.target.files[0].name;
    if(name.slice(-4) == ".pdf"){
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.uploadForm.get('file').setValue(file);
      }
      if(this.uploadForm.get('file').value != ''){
        this.banderatabla = false;
      }
    }else{
      this.banderaextension = false;
    }
  }

  consultardocumentosposibles(){
    this.banderadocumentospendientes = true;
    this.banderadocumentossubidos = true;
    this.perfilService.consultardocumentosposibles(this.data.matricula).subscribe({
      next:(data : any) => {
        this.documentosposibles = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentosposibles.length == 0){
          this.banderadocumentospendientes = false;
        }
        this.consultardocumentossubidos();
      }
    });
  }

  consultardocumentossubidos(){
    this.perfilService.consultardocumentossubidos(this.data.matricula).subscribe({
      next:(data : any) => {
        this.documentossubidos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        if(this.documentossubidos.length == 0){
          this.banderadocumentossubidos = false;
        }
        this.banderaterminado = false;
      }
    });
  }

  consultardocumentosvalidados(){

  }

  abrirmodaldocumento(event){
    this.documentoselecionadoid = event.data.id;
    if(event.data.nombre.substring(0,3) == "(*)"){
      this.documentoselecionadonombre = event.data.nombre.slice(4)
    }else{
      this.documentoselecionadonombre = event.data.nombre;
    }    
    $('#formulario').modal('show');
  }

  abrirmodaldocumento2(event){
    this.documentoselecionadoid = event.data.id;
    if(event.data.nombre.substring(0,3) == "(*)"){
      this.documentoselecionadonombre = event.data.nombre.slice(4)
    }else{
      this.documentoselecionadonombre = event.data.nombre;
    }    
    $('#formulario').modal('show');
  }

  verdocumento(event){
    this.documentoselecionadoid = event.data.idregistro;
    this.documentoselecionadonombre = event.data.nombre;
    this.verdocumentourl2();   
  }

  verdocumentourl(){
    this.src = `${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${this.documentoselecionadoid}`
    this.banderadocumento = false;
    $('#documentos').modal('show');
  }

  verdocumentourl2(){
    $('#cargando').modal('show');
    this.src = "";
    this.banderadocumento = true;
    this.perfilService.verDocumento(this.documentoselecionadoid).subscribe({
      next:(data : ArrayBuffer) => {
        this.src = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
        $('#cargando').modal('hide');
      },
      complete:() => {
        $('#cargando').modal('hide');
        setTimeout(() => {
          $('#documentos').modal('show');
          this.banderadocumento = false;
        }, 1000)
      }
    });
  }

  descargardocumento(){
    this.perfilService.descargarDocumento(this.documentoselecionadoid);
  }

  subirdocumento(){
    var doc = <HTMLSelectElement>document.getElementById('documento');
    $('#cargando').modal('show');
    const formdata = new FormData();
    formdata.append('file', this.uploadForm.get('file').value),
    formdata.append('matricula', this.data.matricula);
    formdata.append('tipo', this.documentoselecionadoid);
    this.perfilService.subirDocumento(formdata).subscribe({
      next:(data : any) => {
        this.respuesta = data;
      },
      error:(err) => {
        console.log(err);
        this.banderatabla = true;
        doc.value = "";
          $('#cargando').modal('hide');
          this.uploadForm.reset({file:''});
      },
      complete:() => {
        // this.myInputVariable.nativeElement = "";
        doc.value = "";
        if(this.respuesta.codigo == "OK"){
          this.banderaterminado = true;
          this.banderatabla = true;
          this.uploadForm.reset({file:''});
          this.consultardocumentosposibles();
          this.semaforoactualizar();
          setTimeout(() => {
            $('#cargando').modal('hide');
          }, 500)
        }else{
          if(this.respuesta.codigo == "ERROR"){
            $('#cargando').modal('hide');
            setTimeout(() => {
              $('#respuesta').modal('show');
            }, 500)
          }
        }
      }
    });
  }

}
