<body>
    <div class="row">
        <div class="col-2 img">
            <div>
                <img src="../../../assets/img/avatar.jpg" alt="..." class="rounded-circle">
            </div>
        </div>
        <div class="col-10">

          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="pills-generales-tab" data-toggle="pill" href="#pills-generales" role="tab" aria-controls="pills-generales" aria-selected="true">Datos Generales</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="pills-complementarios-tab" data-toggle="pill" href="#pills-complementarios" role="tab" aria-controls="pills-complementarios" aria-selected="false">Datos Complementarios</a>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-generales" role="tabpanel" aria-labelledby="pills-generales-tab">
              <form [formGroup]="datosgeneralesForm">
                <div class="row">
                  <div class="col-12">
                      <div class="form-row">
                          <div class="form-group col-4">
                            <label for="nombres">Nombre</label>
                            <input type="text" readonly [formControl]="this.datosgeneralesForm.get('nombres')" class="form-control" id="nombres">
                          </div>
                          <div class="form-group col-4">
                            <label for="apellidop">Apellido Paterno</label>
                            <input type="text" readonly [formControl]="this.datosgeneralesForm.get('apellidop')" class="form-control" id="apellidop">
                          </div>
                          <div class="form-group col-4">
                              <label for="apellidom">Apellido Materno</label>
                              <input type="text" readonly [formControl]="this.datosgeneralesForm.get('apellidom')" class="form-control" id="apellidom">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="form-row">
                          <div class="form-group col-6">
                            <label for="nombres">CURP</label>
                            <input type="text" [formControl]="this.datosgeneralesForm.get('curp')" class="form-control" id="curp">
                            <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors ">Ingrese su CURP.</div>
                            <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.curp.touched || datosgeneralesForm.controls.curp.dirty)">
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors?.required">Ingrese una CURP.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors?.maxlength">CURP no debe de ser mayor de 18 caracteres.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.curp.errors?.minlength">CURP no debe de ser menor de 18 caracteres.</div>
                          </div>
                          </div>
                          <!-- <div class="form-group col-4">
                            <label for="apellidop">Fecha de Nacimiento</label>
                            <input type="date" readonly [formControl]="this.datosgeneralesForm.get('fechanacimiento')" class="form-control" id="fechanac">
                          </div> -->
                          <div class="form-group col-6">
                              <label for="apellidom">Matrícula</label>
                              <input type="text" readonly [formControl]="this.datosgeneralesForm.get('matricula')" class="form-control" id="matricula">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="form-row">
                          <div class="form-group col-4">
                            <label for="nombres">Calle</label>
                            <input type="text" [formControl]="this.datosgeneralesForm.get('calle')" class="form-control" id="calle">
                            <div class="text-danger" *ngIf="datosgeneralesForm.controls.calle.errors">Ingrese su Calle.</div>
                          </div>
                          <div class="form-group col-4">
                            <label for="apellidop">Colonia / Fraccionamiento</label>
                            <input type="text" [formControl]="this.datosgeneralesForm.get('colonia')" class="form-control" id="colonia">
                            <div class="text-danger" *ngIf="datosgeneralesForm.controls.colonia.errors">Ingrese su Colonia/Fraccionamiento.</div>
                          </div>
                          <div class="form-group col-4">
                              <label for="apellidom">Codigo Postal</label>
                              <input type="text" [formControl]="this.datosgeneralesForm.get('codigopostal')" class="form-control" id="codigopostal">
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.codigopostal.errors">Ingrese su Codigo Postal.</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="form-row">
                          <div class="form-group col-4">
                            <label for="nombres">Correo Electronico</label>
                            <input type="text" [formControl]="this.datosgeneralesForm.get('email')" class="form-control" id="email">
                            <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.email.touched || datosgeneralesForm.controls.email.dirty )">
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.email.errors?.required">Ingrese su correo personal.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.email.errors?.pattern">Correo ingresado no es valido.</div>
                          </div>
                          </div>
                          <div class="form-group col-4">
                            <label for="apellidop">Telefono</label>
                            <input type="text" [formControl]="this.datosgeneralesForm.get('telefono')" class="form-control" id="telefono">
                            <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.telefono.touched || datosgeneralesForm.controls.telefono.dirty )">
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.required">Ingrese su telefono.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.maxlength">Numero de telefono no debe exceder los 10 digitos.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.minlength">Numero de telefono no debe ser menos de 10 digitos.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.telefono.errors?.pattern">Numero de telefono solo debe contener caracteres numericos.</div>
                          </div>
                          </div>
                          <div class="form-group col-4">
                            <label for="apellidop">RFC</label>
                            <input type="text" [formControl]="this.datosgeneralesForm.get('rfc')" class="form-control" id="rfc">
                            <div *ngIf="datosgeneralesForm.invalid && (datosgeneralesForm.controls.rfc.touched || datosgeneralesForm.controls.rfc.dirty )">
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.rfc.errors?.required">Ingrese su RFC.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.rfc.errors?.maxlength">RFC no debe de ser mayor de 13 caracteres.</div>
                              <div class="text-danger" *ngIf="datosgeneralesForm.controls.rfc.errors?.minlength">RFC no debe de ser menor de 12 caracteres.</div>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-12">
                      <div class="col-12" *ngIf="!banderadatosgenerales">
                          <button class="btn btn-danger float-right" [disabled]="datosgeneralesForm.invalid" (click)="editardatosgenerales()">Guardar Cambios</button>
                        </div>
                        <div class="col-12" *ngIf="banderadatosgenerales">
                          <button class="btn btn-danger float-right" (click)="modificar()">Modifcar datos</button>
                        </div>
                  </div>
              </div>
              </form>
            </div>
            <div class="tab-pane fade" id="pills-complementarios" role="tabpanel" aria-labelledby="pills-complementarios-tab">
              <form [formGroup]="datoscomplementariosForm">
                <div class="row">
                  <div class="col-12">
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="cedula">Cedula Profesional</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('cedulaprofesional')" class="form-control" id="cedula">
                      </div>
                      <div class="form-group col-4">
                        <label for="discapacidad">Discapacidad</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('discapacidad')" class="form-control" id="discapacidad"> -->
                          <select class="form-control" id="discapacidad">
                            <option *ngFor="let d of discapacidades" [value]="d.id">{{ d.discapacidad }}</option>
                          </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="lentes">Lentes</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('lentes')" class="form-control" id="lentes"> -->
                        <select class="form-control" id="lentes">
                          <option value="NO">NO</option>
                          <option value="SI">SI</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="escuelap">Escuela de Procedencia</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('escuelaprocedencia')" class="form-control" id="escuelap">
                      </div>
                      <!-- <div class="form-group col-4">
                        <label for="municipcion">Municipcio Nacimiento</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('municipionacimiento')" class="form-control" id="municipcion">
                      </div> -->
                      <div class="form-group col-6">
                        <label for="municipior">Municipio de Residencia</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('municipioresidencia')" class="form-control" id="municipior">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="nacionalidad">Nacionalidad</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('nacionalidad')" class="form-control" id="nacionalidad"> -->
                        <select class="form-control" id="nacionalidad">
                          <option *ngFor="let n of naciones" [value]="n.nac_id">{{ n.nac_nacionalidad }}</option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="extranjero">Extranjero</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('extranjero')" class="form-control" id="extranjero"> -->
                        <select  class="form-control" id="extranjero">
                          <option value="NO">NO</option>
                          <option value="SI">SI</option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="estadocivil">Estado Civil</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('estadocivil')" class="form-control" id="estadocivil">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-row">
                      <div class="form-group col-4">
                        <label for="trabaja">Trabaja</label>
                        <!-- <input type="text" [formControl]="this.datoscomplementariosForm.get('trabaja')" class="form-control" id="trabaja"> -->
                        <select  class="form-control" id="trabaja">
                          <option value="NO">NO</option>
                          <option value="SI">SI</option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label for="hijos">Hijos</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('hijos')" class="form-control" id="hijos">
                      </div>
                      <div class="form-group col-4">
                        <label for="nhijos">No. de Hijos</label>
                        <input type="text" [formControl]="this.datoscomplementariosForm.get('numerohijos')" class="form-control" id="nhijos">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                      <button class="btn btn-danger float-right" (click)="editadatoscomplementarios()" [disabled]="datoscomplementariosForm.invalid">Guardar Cambios</button>
                  </div>
              </div>
              </form>
            </div>
          </div>
        </div>
    </div>

    <div class="modal fade" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
              <button type="button" class="close" (click)="cerrarresuesta()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{ respuesta.mensaje }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger" (click)="cerrarresuesta()">Aceptar</button>
            </div>
          </div>
        </div>
      </div>

</body>