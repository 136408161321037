
<div class="card">
    <div class="card-body">
      <div>
        <p>
          <i [class]="icon"></i><span>{{ alumno }}</span><span>  {{ matricula }}</span><span class="float-right"></span>
          <!-- <i [class]="icon"></i><span>{{ alumno }}</span><span>  {{ matricula }}</span><span class="float-right"><i class="fab fa-whatsapp text-success whats"></i><strong>618</strong></span> -->
        </p>
      </div>
        <hr>
      <h6 id="buttomlabel" class="card-title"><strong>Carrera: </strong><span>{{ carrera }}</span><strong class="ml-4"> Semestre: </strong>{{ semestre }} <strong  class="ml-4">Regular: </strong> {{ regular }}</h6>
    </div>
  </div>