<body>
    <div class="row">
        <div class="col-12">
            <div>
                <h3>Estatus de Revisión de Documentación</h3>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <ng2-smart-table class="table table-hover table-borderless" [settings]="settings" [source]="documentos"></ng2-smart-table>
        </div>
    </div>
</body>
