import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  planalumno:{
    carrera:{
      nombre: string
    }
  };

  constructor() { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
  }

}
