<div>
<!-- Se listan las solicitudes -->
    <div>
        <h5>Lista de solicitudes</h5>
        <ng2-smart-table class="table table-hover table-borderless" (userRowSelect)="documentos($event)" [settings]="settings" [source]="listado"></ng2-smart-table>
    </div>
<!-- modal para subir y bajar documentos -->
    <div class="modal" id="documentos" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">Formatos
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-6">
                        <h5>Descargar formato</h5>
                        <table class="table table-light table-striped table-hover table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th style="width: 75%">Título</th>
                                    <th>Descarga</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of archivos">
                                    <td>{{a.formato}}</td>
                                    <td><a class="btn" (click)="descargar( a.id, a.formato )"><i class="fas fa-download text-danger"></i></a></td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                    <div class="col-6">
                        <h5>Subir documentos</h5>
                        <table class="table table-light table-striped table-hover table-borderless">
                            <thead class="bg-danger text-white">
                                <tr>
                                    <th style="width: 65%">Título</th>
                                    <th>Subir documento</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of archivosalumno">
                                    <td>{{a.formato}}</td>
                                    <td>
                                        <label for="documento" class="btn btn-sm text-danger"><i class="fas fa-upload"></i></label>
                                        <input type="file" id="documento" (change)="handleFileSelect($event, a.id)" enctype="multipart/form-data" accept="image/jpeg, application/pdf" style="display: none">  
                                    </td>
                                </tr>
                            </tbody>
                            
                        </table>
                    </div>
                </div>
                
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
            </div>
        </div>
        </div>
    </div>
<!-- Modal donde se accepta y se envia la informacion -->
    <div class="modal" id="aceptacion" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            ¿Estas seguro de subir el archivo?
            <p></p>
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
            <a class="btn btn-danger text-white" (click)="enviarDoc()">Enviar</a>
            </div>
        </div>
        </div>
    </div>
<!-- Modal de respuestas -->
    <div class="modal" id="respuesta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            </div>
            <div class="modal-body">
            {{ response.mensaje }}
            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Salir</button>
            </div>
        </div>
        </div>
    </div>
</div>

