import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class ServicioSocialService {

  constructor(private http: HttpClient, private global: Global) { }

  consultardatos(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/Alumno/datos `, datos, { headers:this.global.headers })
  }
  consultarsemestre(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/Alumno/datos `, datos, { headers:this.global.headers })
  }
  actualizardatos(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/Alumno/datos/actualizar `, datos, { headers:this.global.headers })
  }
  solicitud(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazas/Alumno/Solicitud `, datos, { headers:this.global.headers })
  }
  descargarDocumento(matricula: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/internado/${matricula}`, { responseType: 'arraybuffer' })
  }
  descargarDocumento1(id: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documento/${id}`, { responseType: 'arraybuffer' })
  }
  consultarArchivos(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/formatos/solicitud`, datos, {headers:this.global.headers })
  }
  consultarArchivosAlumno(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documentos/solicitud/alumno`, datos, {headers:this.global.headers })
  }
  consultarArchivosAlumnoSolicitud(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documentos/solicitudid/alumno`, datos, {headers:this.global.headers })
  }
  subirArchivos(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/documentos/solicitud/insert`, datos, {headers:this.global.headers })
  }
  consultarlistado(datos){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/ssPlazasAdm/solicitudes`, datos, {headers:this.global.headers })
  }
}
