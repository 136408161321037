<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-6">
                <app-bienvenido icon="fas fa-circle" alumno="{{ data.nombres | uppercase }} {{ data.apellidop | uppercase }} {{ data.apellidom | uppercase }}" 
                carrera="{{planalumno.carrera.nombre}}" matricula="{{ data.matricula }}"  semestre="{{ data.semestre }}"></app-bienvenido>
                  <div class="row" id="top"></div>

                <!-------------------------------------------------------------------------------------------------------------------------------------------------------------------->

                <div class="row">
                    <div class="col-sm-12">
                        <div>
                            <div _ngcontent-uva-c30="" class="text-center"><h3 _ngcontent-uva-c30="">Expediente</h3>
                                <p style="
                                    text-align: left;
                                ">A continuación puedes subir los archivos que solicita la institución para ir creando tu expediente.</p>
                                <p style="
                                    text-align: left;
                                ">Ten en cuenta las siguientes indicaciones para realizar tu proceso de manera correcta.</p>
                                <p style="text-align: left;">
                                    1.- Todos tus documentos tienen que ir en formato <strong>PDF</strong> <br>
                                    2.- Los archivos no deben tener un peso mayor a un <strong>1 Mega</strong> <br>
                                    3.- Los registros con <strong>(*)</strong> son obligatorios. <br>
                                    4.- En caso de dejar documentos pendientes por subir no obligatorios, contaras con <strong>4 semanas</strong> para hacer la carga de los mismos.
                                </p></div>
                            <table class="table table-hover">
                                <thead>
                                    <tr class="bg-danger text-white">
                                        <th scope="col">No.</th>
                                        <th scope="col">Documento</th>
                                        <th scope="col">Estado</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let documento of documentosSubidos; let i = index">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ documento.nombre }}</td>
                                        <td></td>
                                        <td></td>
                                        <td><button class="btn btn-danger" (click)="imprimirDocumento(documento.id)">Imprimir</button></td>
                                        <td>
                                            <button (click)="selectDocumento(documento)" type="button" class="close" aria-label="Close"  data-toggle="modal" data-target="#exampleModalBorrarCenter">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <div class="row" id="top"></div>
                    <button type="button" class="btn btn-danger" data-toggle="modal" data-target="#exampleModalCenter">
                        Subir Documentos
                    </button>
                  </div>

            <!-- Modal SUBIR DOCUMENTO -->
            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Documentos Solicitados</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [hidden]="!bandera">
                        <form [formGroup]="uploadForm">
                            <select id="scripts" class="form-control">
                                <option class="option" *ngFor="let documento of documentos" [value]="documento.nombre">{{ documento.nombre }}</option>
                            </select>
                            <div class="row" id="top"></div>
                            <input enctype="multipart/form-data" accept="application/pdf" #inputFile type="file" name="documento" (change)="onFileSelect($event)">
                            <div class="row" id="top"></div> 
                            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cerrar</button>
                            <button type="submit" (click)="onSubmit()"  class="btn btn-danger" [disabled]="this.uploadForm.get('file').value.size > 3145728 || this.uploadForm.get('file').value == '' ">Guardar</button>
                        </form>
                    </div>
                    <div [hidden]="bandera">
                        <label>{{this.respuesta2.mensaje}}</label>
                    </div>
                </div>
                </div>
            </div>
            </div>


            <!-- Modal ELIMINAR DOCUMENTO -->
            <div class="modal fade" id="exampleModalBorrarCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>¿Estás seguro de eliminar este documento?<br>Se eliminará de forma <strong>PERMANENTE </strong></div>
                        <div>{{ this.documentoSelectionado.nombre }}</div>
                        <div class="row" id="top"></div>
                        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cerrar</button>
                        <button type="submit" class="btn btn-danger" (click)="borrarDocumento()" data-dismiss="modal">Eliminar</button>
                    </div>
                    </div>
                </div>
            </div>

            <!-- Modal RESPUESTA ELIMINAR DOCUMENTO -->
            <div class="modal fade" id="exampleModalBorrarRespCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarRespTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ this.respuesta.mensaje }}</div>
                        <div class="row" id="top"></div>
                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>

            <!-- Modal RESPUESTA SUBIR DOCUMENTO -->
            <div class="modal fade" id="exampleModalSubirRespCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarRespTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ this.respuesta2.mensaje }}</div>
                        <div class="row" id="top"></div>
                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" (click)="updateListado();">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>

            <!-- Modal RESPUESTA SUBIR DOCUMENTO -->
            <div class="modal fade" id="exampleModalSalirCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterBorrarRespTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">¡Atención!</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div>{{ this.respuesta2.mensaje }}</div>
                        <div class="row" id="top"></div>
                        <button type="button" class="btn btn-danger mr-2" data-dismiss="modal" (click)="routers()">Cerrar</button>
                    </div>
                    </div>
                </div>
            </div>

                  <!-------------------------------------------------------------------------------------------------------------------------------------------------------------------->

            </div>
        </div>
    </div>
</body>



<!--
                        <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label>Acta de Nacimiento</label><br>
                            <input type="file" name="acta" (change)="onFileSelectActa($event)"/><br>
                        </div>
                        <div class="form-group">
                            <label>Certificado</label><br>
                            <input type="file" name="certificado" (change)="onFileSelectCertificado($event)"/><br>
                        </div>
                        <div class="form-group">
                            <label>CURP</label><br>
                            <input type="file" name="curp" (change)="onFileSelectCurp($event)"/><br>
                        </div>
                        <div class="form-group">
                            <label>Identificación</label><br>
                            <input type="file" name="identificacion" (change)="onFileSelectIdentificacion($event)"/><br>
                        </div>
                        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cerrar</button>
                        <button type="submit" class="btn btn-danger" [disabled]="this.uploadForm.invalid">Guardar</button>
                    </form>
-->