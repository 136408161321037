import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Global } from 'src/global';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {

  constructor(private http: HttpClient, private global: Global) { }

  cambiarPass(nuevaP){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Login/newpassword/user`, nuevaP)
  }

  consultardocumentosposibles(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentos/${matricula}`, null)
  }

  consultardocumentossubidos(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentosAlumnos/${matricula}`, null)
  }

  borrardocumento(idDocumento){
    return this.http.delete(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/documentosAlumnos/${idDocumento}`)
  }

  subirDocumento(uploadDocumento){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/documentosPreacta/upload`, uploadDocumento)
  }

  descargarDocumento(matricula: string){
    window.open(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${matricula}`, null)
  }

  verDocumento(matricula: string){
    return this.http.get(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/DocumentosAlumnos/documento/${matricula}`, { responseType: 'arraybuffer' })
  }

  consultardatosgeneralesalumno(matricula){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/datos/${matricula}/0`, null)
  }

  editardatosgeneralesalumno(datos){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/editar `, datos, { headers:this.global.headers })
  }

  verPermiso(matricula: string){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/docsAlu/permiso/${matricula}`, null)
  }

  consultardatoscomplementariosalumnoa(matricula, matriculap){
    return this.http.post(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/listaDatos/${matricula}/${matriculap}`, null)
  }

  editardatoscmp(datos){
    return this.http.put(`${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/Famen/Alumno/editarDatosCmp/`, datos, { headers:this.global.headers })
  }



}
