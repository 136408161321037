import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  alumno;

  changePasswordForm = new FormGroup({
    passwordA: new FormControl('', Validators.required),
    password: new FormControl('',[Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)]),
    passwordConfirmation: new FormControl('',[Validators.required, Validators.pattern(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)])
  });

  response = {
    codigo: '',
    detalle: '',
    mensaje: ''
  }

  semaforo = JSON.parse(localStorage.getItem('semaforo'));

  banedra:boolean = true;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.alumno = JSON.parse(localStorage.getItem('alumno'))
  }

  onSubmit(){
    if(this.changePasswordForm.controls["password"].value != this.changePasswordForm.controls["passwordConfirmation"].value){
      this.changePasswordForm.setErrors({ noMatch: true });
    }
    else{
      if(this.changePasswordForm.controls["passwordA"].value === this.changePasswordForm.controls["password"].value){
        this.changePasswordForm.setErrors({ iguales: true })
      }
      else{
        let alumno = {
          
          usuario: this.alumno.matricula,
          password: this.changePasswordForm.controls["password"].value,
          oldpassword: this.changePasswordForm.controls["passwordA"].value
        }
        this.authService.cambiarPass(alumno).subscribe({
          next:(res : any) =>{
            this.response = res;
            // this.router.navigateByUrl('/inicio')
          },
          error:(err) => {
            throw new Error('Error en la peticion');
          },
          complete:() => {
            $('#myModal').modal('show');
            if(this.response.codigo == "OK"){
              this.banedra = false;
            }
          }
        });
      }
    }
  }

  redirect(){
    if(this.alumno.semestre == "1" && this.semaforo.documentos == "S"){
      this.router.navigateByUrl('/perfil/documentos');
    }else{
      this.router.navigateByUrl('/inicio')
    }
  }

}