<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-7">
                        <app-bienvenido icon="fas fa-circle" alumno="{{ data.nombres | uppercase }} {{ data.apellidop | uppercase }} {{ data.apellidom | uppercase }}" 
                        carrera="{{planalumno.carrera.nombre}}" matricula="{{ data.matricula }}"  semestre="{{ data.semestre }} " regular="{{ data.regular }}"></app-bienvenido>
                        <div class="row" id="top"></div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                                <h3>Internado</h3>
                                <hr>
                                <nav>
                                    <a class="card-link" routerLink="/servicio/solicitud" routerLinkActive="active">Solicitar</a>
                                    <a class="card-link" routerLink="/servicio/listado" routerLinkActive="active">Consultar solicitudes</a>
                                    <a class="card-link" routerLink="/perfil/datos-personales" routerLinkActive="active">Datos Personales</a>  
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="mensaje" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">¡Atención!</h5>
                    <button type="button" class="close" (click)="salir()" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                {{ mensajesmodal }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" (click)="salir()" data-dismiss="modal">Salir</button>
                </div>
            </div>
        </div>
    </div>
</body>