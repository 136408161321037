 import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { Global } from '../../../global';
import { environment } from 'src/environments/environment.prod';
declare var $: any;

@Component({
  selector: 'app-navbar-izq',
  templateUrl: './navbar-izq.component.html',
  styleUrls: ['./navbar-izq.component.css']
})
export class NavbarIzqComponent implements OnInit {

  currentApplicationVersion = environment.appVersion;

  link:{
    title: string,
    path: string,
    icon: string,
    disabled: boolean,
    semestre: string
  }

  semaforo = {
    documentos: 'S',
    horario: 'N',
    inscripcion: 'N'
  }
  
  banderaaviso:boolean = true;
  respaldobandera = "1";

  alumno;

  links = [
    { title: "Inicio", path:'/inicio', icon:'fas fa-home', disabled: false, hidden: false, semestre:'-1' },
    { title: "Inscripción", path:'/inscripcion', icon:'far fa-compass',  disabled: false, hidden: true, semestre:'-1' },
    { title: "Inscripción", path:'/inscripciones/horario', icon:'far fa-compass',  disabled: false, hidden: true, semestre:'-1' },
    // { title: "Documentos", path:'/documentos', icon:'fas fa-file-upload', disabled: false, hidden: true },
    { title: "Horario", path:'/horario', icon:'far fa-calendar-alt', disabled: false, hidden: true, semestre:'-1'},
    { title: "Calificaciones", path:'/inscripciones/calificaciones', icon:'fas fa-clipboard-list', disabled: false, hidden:false, semestre:'-1' },
    { title: "Trámites", path:'/tramites', icon:'far fa-envelope',  disabled: false, hidden: false, semestre:'-1' },
    //{ title: 'Servicio Social Universitario', path:'/servicio-universitario/agregar', icon: 'fas fa-vials', disabled:false, hidden:false, semestre:'-1' },
    // { title: "Examen Profesional", path:"/examen-profesional/documentos", icon:"fas fa-user-md", disabled:false, hidden:false },
    //{ title: 'Internado', path:'/servicio', icon:'fas fa-notes-medical', disabled:false, hidden:true, semestre:'1'},
    { title: 'Kárdex', path:'/kardex', icon:'fas fa-th-list', disabled:false, hidden:false, semestre:'-1' },
    { title: 'Mi Perfil', path:'/perfil/datos-personales', icon:'fas fa-user',  disabled:false, hidden:false, semestre:'-1' }
  ]

  constructor(private router: Router, private sharedServe: SharedService, private global: Global) { }

  ngOnInit(): void {

    this.respaldobandera = localStorage.getItem('aviso');

    if((this.banderaaviso == true) && (this.respaldobandera != '0') ){
      $('#aviso').modal('show');
      localStorage.setItem('aviso', "0");
    }

    this.alumno = JSON.parse(localStorage.getItem('alumno'));
    let estatus=this.alumno.estatus;
    if( estatus.indexOf("INGRESO")==-1 ){
      this.links.splice(1,3);
    }

    this.semaforo = JSON.parse(localStorage.getItem('semaforo'));
    let semestre = parseInt(this.alumno.semestre);
    let plesemestres =  parseInt(this.alumno.plesemestres);
    //console.log(this.alumno);
    if (semestre>=plesemestres){
      for(var j = 0; j < this.links.length; j++){
        if(this.links[j].semestre=='1'){
          this.links[j].hidden = false;
        }
      }
    }
    
    if((this.alumno.estatus == "NUEVO INGRESO") && (this.alumno.semestre <= "0")){
      if((this.semaforo == null || undefined)){
        setTimeout(() => {
          if(this.semaforo.horario == "S"){
            
            for(var i = 0; i < this.links.length; i++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                if(this.semaforo.inscripcion == "S"){
                  if(this.links[i].path == "/inscripcion"){
                    this.links[i].hidden = false;
                  }
                }
              }else{
                if(this.links[i].path == "/inscripciones/horario"){
                  this.links[i].hidden = false;
                }
              }
            }
    
            for(var x = 0; x < this.links.length; x++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                if(this.semaforo.horario == "S"){
                  if(this.links[x].path == "/horario"){
                    this.links[x].hidden = false;
                  }
                }
              }else{
                if(this.links[x].path == "/horario"){
                  this.links[x].hidden = false;
                }
              }
            }
    
            for(var x = 0; x < this.links.length; x++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                // if(this.semaforo.horario == "S"){
                //   if(this.links[x].path == "/horario"){
                //     this.links[x].hidden = false;
                //   }
                // }
              }else{
                if(this.links[x].path == "/inscripciones/calificaciones"){
                  this.links[x].hidden = false;
                }
              }
            }
    
            for(var x = 0; x < this.links.length; x++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                // if(this.semaforo.horario == "S"){
                //   if(this.links[x].path == "/horario"){
                //     this.links[x].hidden = false;
                //   }
                // }
              }else{
                if(this.links[x].path == "/kardex"){
                  this.links[x].hidden = false;
                }
              }
            }
    
          }else{
            this.semaforoactualizar();
          }
        }, 4000)
      }else{
        setTimeout(() => {
          if(this.semaforo.horario == "S"){
            
            for(var i = 0; i < this.links.length; i++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                if(this.semaforo.inscripcion == "S"){
                  if(this.links[i].path == "/inscripcion"){
                    this.links[i].hidden = false;
                  }
                }
              }else{
                if(this.links[i].path == "/inscripciones/horario"){
                  this.links[i].hidden = false;
                }
              }
            }
    
            for(var x = 0; x < this.links.length; x++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                if(this.semaforo.horario == "S"){
                  if(this.links[x].path == "/horario"){
                    this.links[x].hidden = false;
                  }
                }
              }else{
                if(this.links[x].path == "/horario"){
                  this.links[x].hidden = false;
                }
              }
            }
    
            for(var x = 0; x < this.links.length; x++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                // if(this.semaforo.horario == "S"){
                //   if(this.links[x].path == "/horario"){
                //     this.links[x].hidden = false;
                //   }
                // }
              }else{
                if(this.links[x].path == "/inscripciones/calificaciones"){
                  this.links[x].hidden = false;
                }
              }
            }
    
            for(var x = 0; x < this.links.length; x++){
              if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
                // if(this.semaforo.horario == "S"){
                //   if(this.links[x].path == "/horario"){
                //     this.links[x].hidden = false;
                //   }
                // }
              }else{
                if(this.links[x].path == "/kardex"){
                  this.links[x].hidden = false;
                }
              }
            }
    
          }else{
            this.semaforoactualizar();
          }
        }, 100)
      }
    }else{
      for(var w = 0; w < this.links.length; w++){
        if(this.links[w].path == "/kardex"){
          this.links[w].hidden = false;
        }
      }
      for(var f = 0; f < this.links.length; f++){
        if(this.links[f].path == "/inscripciones/calificaciones"){
          this.links[f].hidden = false;
        }
      }
      for(var d = 0; d < this.links.length; d++){
        if(this.links[d].path == "/horario"){
          this.links[d].hidden = false;
        }
      }
      for(var v = 0; v < this.links.length; v++){
        if(this.links[v].path == "/inscripciones/horario"){
          this.links[v].hidden = false;
        }
      }
      
    }
    // console.log(this.alumno);
    // if (this.alumno.semestre>"9"){
    //   for(var j = 0; j < this.links.length; j++){
    //     console.log(this.links[j]);
    //     if(this.link[j].semestre=='1'){
    //       console.log('aqui')
    //       this.link[j].hidden = false;
    //     }
    //   }
    // }
  }

  semaforoactualizar(){
    this.sharedServe.verPermiso(this.alumno.matricula).subscribe({
      next:(resp: any) => {
        localStorage.setItem('semaforo', JSON.stringify(resp));
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        this.semaforo = JSON.parse(localStorage.getItem('semaforo'));
        if(this.alumno.semestre <= "1" && this.semaforo.documentos == "S" && this.semaforo.inscripcion == "N" && this.semaforo.horario == "N" && this.alumno.estatus == "NUEVO INGRESO"){
          this.router.navigateByUrl('/perfil/documentos');
        }
        
        for(var i = 0; i < this.links.length; i++){
          if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
            if(this.semaforo.inscripcion == "S"){
              if(this.links[i].path == "/inscripcion"){
                this.links[i].hidden = false;
              }
            }
          }else{
            if(this.links[i].path == "/inscripciones/horario"){
              this.links[i].hidden = false;
            }
          }
        }

        for(var x = 0; x < this.links.length; x++){
          if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
            if(this.semaforo.horario == "S"){
              if(this.links[x].path == "/horario"){
                this.links[x].hidden = false;
              }
            }
          }else{
            if(this.links[x].path == "/horario"){
              this.links[x].hidden = false;
            }
          }
        }

        for(var x = 0; x < this.links.length; x++){
          if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
            // if(this.semaforo.horario == "S"){
            //   if(this.links[x].path == "/horario"){
            //     this.links[x].hidden = false;
            //   }
            // }
          }else{
            if(this.links[x].path == "/inscripciones/calificaciones"){
              this.links[x].hidden = false;
            }
          }
        }

        for(var x = 0; x < this.links.length; x++){
          if(this.alumno.semestre <= "1" && this.alumno.estatus == "NUEVO INGRESO"){
            // if(this.semaforo.horario == "S"){
            //   if(this.links[x].path == "/horario"){
            //     this.links[x].hidden = false;
            //   }
            // }
          }else{
            if(this.links[x].path == "/kardex"){
              this.links[x].hidden = false;
            }
          }
        }
      }
    });
  }

  logout(){
    localStorage.clear();
    localStorage.setItem('aviso', '0');
    this.router.navigateByUrl('/login');
  }

  isDisabled(link: any){
    return link;
  }

  verTutorial(){
    var video = window.open();
    video.document.write(`<video controls autoplay style="position: fixed; right: 0; bottom: 0; min-width: 100%; min-height: 100%;" width="700" height="500" src="${this.global.protocolo}://${this.global.backendUrl}/${this.global.contexto}/tutorial.mp4"></video>`)
  }

}
