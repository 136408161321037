<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-7">
                        <app-bienvenido icon="fas fa-circle" alumno="{{ data.nombres | uppercase }} {{ data.apellidop | uppercase }} {{ data.apellidom | uppercase }}" 
                        carrera="{{planalumno.carrera.nombre}}" matricula="{{ data.matricula }}"  semestre="{{ data.semestre }}" regular="{{ data.regular }}"></app-bienvenido>
                        <div class="row" id="top"></div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <div class="card">
                            <div class="card-body">
                              <h3>Mi Perfil</h3>
                              <nav>
                                <a class="card-link" routerLink="/perfil/datos-personales" routerLinkActive="active">Datos Personales</a> 
                                <a class="card-link" routerLink="/perfil/documentos" routerLinkActive="active">Documentos</a> 
                                <a class="card-link" routerLink="/perfil/cambiar-contraseña" routerLinkActive="active">Contraseña</a> 
                              </nav>
                            </div>
                          </div>
                    </div>
                </div>
                <div class="row" id="top">
                    <div class="col-lg-12">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>