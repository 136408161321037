import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
declare var $ : any;


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  @Input() icon: String;
  @Input() placeholder: String;
  @Input() type: String;
  @Input() control: FormControl;

  constructor() { }

  ngOnInit(): void {

  }

  // showErrors(){
  //   const { dirty, touched, errors } = this.control;
  //   return dirty && touched && errors;
  // }

}
