import { Component, OnInit } from '@angular/core';
import { KardexService } from '../services/kardex.service';



@Component({
  selector: 'app-kardex',
  templateUrl: './kardex.component.html',
  styleUrls: ['./kardex.component.css']
})
export class KardexComponent implements OnInit {

  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  planalumno:{
    carrera:{
      nombre: string
    }
  };

  datoshistorialkardex;
  datoscabecerakardex;
  bandera2:boolean = true;

  constructor(private kardexService: KardexService) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.planalumno = JSON.parse(localStorage.getItem('planAlumno'));
    this.consultardatoskardex();
  }

  consultarhistorialkardex(){
    this.kardexService.consultarhistorialcabecera(this.data.matricula).subscribe({
      next:(data : any) => {
        this.datoshistorialkardex = data;
      },
      error:(err) => {
        console.log(err);
      }, 
      complete:() => {
        setTimeout(() => {
          this.bandera2 = false;
        }, 100)
      }
    });
  }

  consultardatoskardex(){
    this.kardexService.consultardatoskardex(this.data.matricula).subscribe({
      next:(data : any) => {
        this.datoscabecerakardex = data;
      },
      error:(err) => {
        console.log(err);
      }, 
      complete:() => {
        this.consultarhistorialkardex();
      }
    });
  }


}
