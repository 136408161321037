<body>
    <div class="container-fluid">
        <div class="row" id="top"></div>
        <div class="row">
            <div class="col-lg-2">
                <app-navbar-izq></app-navbar-izq>
            </div>
            <div class="col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <i class="fas fa-circle"></i>
                        <span>{{ data.nombres | uppercase }} {{ data.apellidop | uppercase }} {{ data.apellidom | uppercase }} {{ data.matricula }}</span>
                    </div>
                  </div>

                  <div class="row" id="top"></div>

                <!-- Modal Inscripcion-->
                <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="inscripcionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">¡Atención!</h5>
                            <button type="button" class="close" (click)="resultado()" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            </div>
                            <div class="modal-body">
                            {{ this.respuesta.mensaje }}
                            <div class="row" id="top"></div>
                            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="resultado()">Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</body>