import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl,Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var $ : any;

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset-pass.component.html',
  styleUrls: ['./reset-pass.component.css']
})
export class ResetPassComponent implements OnInit {

  resetForm = new FormGroup({
    matricula: new FormControl('', Validators.required)
  });

  response = {
    codigo: '',
    detalle: '',
    mensaje: ''
  }

  bandera:boolean = true;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(){
    if(this.resetForm.invalid){
      return;
    }else{
      this.bandera = false;
      this.authService.resetPass(this.resetForm.controls["matricula"].value).subscribe({
        next:(resp: any) => {
          this.response = resp;
        }, 
        complete:() => {
          $('#myModal').modal('show');
          this.bandera = true;
        },
        error:() => {
          throw new Error("Error al resetear contraseña");
        }
      });
    }
  }

  redirect(){
    if(this.response.codigo === "OK"){
      this.router.navigateByUrl("/login");
    }
  }

}
