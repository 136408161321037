import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarIzqComponent } from './navbar-izq/navbar-izq.component';
import { RouterModule } from '@angular/router';
import { BienvenidoComponent } from './bienvenido/bienvenido.component';
import { TimerComponent } from './timer/timer.component';
import { CountdownModule } from 'ngx-countdown';
import { ModalComponent } from './modal/modal.component';



@NgModule({
  declarations: [InputComponent, NavbarIzqComponent, BienvenidoComponent, TimerComponent, ModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CountdownModule,
  ],
  exports:[InputComponent, NavbarIzqComponent, BienvenidoComponent, TimerComponent]
})
export class SharedModule { }
