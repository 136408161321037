import { Component, OnInit } from '@angular/core';
import { ServicioSocialService } from '../services/servicio-social.service';
import { saveAs } from 'file-saver';
declare var $:any;
declare var _:any;
@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoSolicitudesComponent implements OnInit {

  constructor(private service:ServicioSocialService) { }

  ngOnInit(): void {
    this.data = JSON.parse(localStorage.getItem('alumno'));
    this.consulta();
    
    
  }
  data:{
    apellidom: string;
    apellidop: string;
    matricula: string;
    nombres: string;
    planestudio: {
      clave: string;
      estatus: string;
      nombre: string;
    }
    semestre: string;
  };

  settings = {
    columns:{
      periodo:{
        title:'Periodo',
        editable:false,
        width:'15%'
      },
      fecha:{
        title:'Fecha de solicitud',
        editable:false,
        width:'15%'
      },
      semestre:{
        title:'Semestre de solicitud',
        editable:false,
        width:'10%'
      },
      
      plaza:{
        title:'Plaza',
        editable:false,
        width:'20%'
      },
      comentario:{
        title:'Comentarios',
        editable:false,
        width:'20%'
      },
      status:{
        title:'Fecha de solicitud',
        editable:false,
        width:'10%'
      }
    },
    actions:{
      add:false,
      edit:false,
      delete:false,
      columnTitle:'',
      position: 'right'
    },
    pager:{
      perPage:10
    },
    noDataMessage:'Sin datos disponibles.'        
  };

  listado;
  filtro = [];
  archivos;
  archivosalumno;
  response = {
    codigo: '',
    detalle: '',
    mensaje: ''
  }
  datosAlu;
  respuesta;
  respuesta1;
  bandera : boolean = true;
  doc;
  id_formato;
  tipo;
  id_solicitud;
  semestre_solicitud;
  consulta(){
    let jason ={
      alumnomatricula:this.data.matricula
    }
    this.service.consultarlistado(jason).subscribe({
      next:(data : any) =>{
        this.listado = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        
        //console.log(this.listado);
        // for (let i = 0; i < this.listado.length; i++) {
        //   this.filtro.push({
        //     id:this.listado[i].solicitudid,
        //     periodo:this.listado[i].periodo,
        //     fecha:this.listado[i].fecha,
        //     status:this.listado[i].status
        //   });
          
        // }console.log(this.filtro);
          
      }
    });
  }
  // consultaSemestre(){
  //   let jason = {
  //     matricula: this.data.matricula
  //   }
  //   this.service.consultarsemestre(jason).subscribe({
  //     next:(data : any) =>{
  //       this.datosAlu = data
  //     },
  //     error:(err) => {
  //       console.log(err);
  //     },
  //     complete:() => {
  //       this.consultarArchivos();
  //       this.consultarArchivosAlumno();
  //     }
  //   });
  // }
  documentos($event){
    console.log($event.data);
    this.id_solicitud= $event.data.solicitudid;
    this.semestre_solicitud = $event.data.semestre
    // this.consultaSemestre();
    this.consultarArchivosAlumno();
    this.consultarArchivos();
    $('#documentos').modal('show');
  }
  consultarArchivos(){
    //console.log(this.data);
    
    let jason={
      semestre:this.semestre_solicitud
      
    }
    this.service.consultarArchivos(jason).subscribe({
      next:(data : any) =>{
        this.archivos = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.archivos);
      }
  });
  }
  consultarArchivosAlumno(){
    let jason={
      matricula:this.data.matricula,
      semestre:this.semestre_solicitud,
      id:this.id_solicitud
    }
    this.service.consultarArchivosAlumnoSolicitud(jason).subscribe({
      next:(data : any) =>{
        this.archivosalumno = data;
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        //console.log(this.archivos);
      }
  });
  }
  handleFileSelect(evt,id){
    //console.log(evt);

    var files = evt.target.files;
    var file = files[0];
    if (file.size<1000000){
      if(file.type == "image/jpeg"){
        if (files && file) {
          var reader = new FileReader();
          reader.onload =this._handleReaderLoadedimg.bind(this);
          reader.readAsBinaryString(file);
          this.tipo= 'jpeg';
          this.id_formato = id;
          $('#documentos').modal('hide');
          $('#aceptacion').modal('show');
        }
      }else{
        if(file.type == "application/pdf"){
          if (files && file) {
              var reader = new FileReader();
              reader.onload =this._handleReaderLoadedpdf.bind(this);
              reader.readAsBinaryString(file);
          }
          this.id_formato = id; 
        this.tipo= 'pdf';
        $('#documentos').modal('hide');
        $('#aceptacion').modal('show');
        }
      }
    }else {
      this.respuesta={
        codigo:'',
        detalle:'El archivo debe ser menor a 1 MB',
        mensaje:'El archivo debe ser menor a 1 MB'
      };
      $('#respuesta').modal('show');
    }
    
    files = '';
  }

  _handleReaderLoadedimg(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.doc = btoa(binaryString);
  }

  _handleReaderLoadedpdf(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.doc = btoa(binaryString);
  }

  generar(){
    $('#aceptar').modal('show');
  }

  enviarDoc(){
    let jason ={
      formato:this.id_formato,
      archivo: this.doc,
      extencion:this.tipo,
      matricula: this.data.matricula
    } 
    //console.log(jason);
    this.service.subirArchivos(jason).subscribe({
      next:(data : any) =>{
        this.response = data
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        $('#aceptacion').modal('hide');
        $('#respuesta').modal('show')
        
        this.id_formato = '';
        this.doc = '';
        this.tipo = '';
      }
    });
  }

  descargar(id, formato){
    this.service.descargarDocumento1(id).subscribe({
      next:(data : ArrayBuffer) =>{
        this.respuesta1 = new Uint8Array(data);
      },
      error:(err) => {
        console.log(err);
      },
      complete:() => {
        var blob = new Blob([this.respuesta1], {type: "application/pdf;charset=utf-8"});
        saveAs(blob, formato+' - INTERNADO MÉDICO DE PREGRADO');
      }
  });
  }

}
