import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PasswordComponent } from './password/password.component';
import { ResetPassComponent } from './reset-pass/reset-pass.component';


const routes: Routes = [
  { path:'login', component: LoginComponent},
  { path:'changePass', component:PasswordComponent },
  { path:'resetPass', component:ResetPassComponent },
  { path:'', redirectTo:'/login', pathMatch:'full' }  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
